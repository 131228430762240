import React, { useCallback, useRef, useState } from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import './home.scss';
import { getClasses } from '@/api/class';
import { getDrives } from '@/api/drive';
import { getPersons } from '@/api/person';
import { getTeams } from '@/api/team';
import { getTractors } from '@/api/tractor';
import { IClass } from '@/interfaces/class.interfaces';
import { IDrive } from '@/interfaces/drive.interfaces';
import { IPerson } from '@/interfaces/person.interfaces';
import { ITeam } from '@/interfaces/team.interfaces';
import { ITractor } from '@/interfaces/tractor.interfaces';

const cards = {
  class: 'Klassen Administration',
  drive: 'Fahrten Administration',
  person: 'Fahrer Administration',
  team: 'Team Administration',
  tractor: 'Tractoren Administration',
  statsOverview: 'Ranking für Klassen',
  class2New: 'Klasse splitten oder für nächste Quali',
  kioskMode: 'Klassen Administration',
  weighing: 'Waage - Trecker wiegen',
  teamOverview: 'Teamübersicht',
  statistic: 'Statistik',
};

interface ISummaryProps {
  persons: IPerson[] | null;
  tractors: ITractor[] | null;
  drives: IDrive[] | null;
  classes: IClass[] | null;
  teams: ITeam[] | null;
  setShowPDF?: any;
}

const Summary = ({ persons, tractors, drives, classes, teams, setShowPDF }: ISummaryProps) => (
  <div>
    {setShowPDF && (
      <div
        style={{
          position: 'absolute',
          top: '5vw',
          right: '5vw',
        }}
      >
        <Button onClick={() => setShowPDF(false)}>Zurück</Button>
      </div>
    )}
    <div style={{ margin: '5vw' }}>
      {teams?.map((team) => {
        return (
          <div key={team._id} style={{ marginBottom: '100px' }}>
            <h2>{team.name}</h2>
            <div>{team.description}</div>
            <div>Anzahl Frühstück {team.breakFirstPersonCount}</div>
            <div style={{ marginTop: '30px' }}>
              {classes?.map((classe) => {
                const teamPersons = persons?.filter((x) => x.teamId === team._id) || [];
                const personIds = teamPersons.map((person) => person._id);
                const classDrives =
                  drives?.filter(
                    (x) => x.classId === classe._id && personIds.includes(x.personId)
                  ) || [];
                if (classDrives.length === 0) {
                  return null;
                }
                return (
                  <div key={classe._id}>
                    <h5>{classe.name}</h5>
                    <div>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Trecker</th>
                            <th>Startnummer</th>
                            <th>Leistung</th>
                            <th>Baujahr</th>
                          </tr>
                        </thead>
                        <tbody>
                          {classDrives.map((drive) => {
                            const person = teamPersons.find((x) => drive.personId === x._id);
                            const tractor = tractors?.find((x) => drive.tractorId === x._id);
                            return (
                              <tr key={drive._id}>
                                <td key={drive._id}>
                                  {person ? person.firstName + ' ' + person.lastName : ''}
                                </td>
                                <td key={drive._id}>{drive.startNumber}</td>
                                <td key={drive._id}>{tractor?.name}</td>
                                <td key={drive._id}>{tractor?.power}</td>
                                <td key={drive._id}>{tractor?.year}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

const Home = () => {
  const [persons, setPersons] = useState<IPerson[] | null>(null);
  const [tractors, setTractors] = useState<ITractor[] | null>(null);
  const [drives, setDrives] = useState<IDrive[] | null>(null);
  const [classes, setClasses] = useState<IClass[] | null>(null);
  const [teams, setTeams] = useState<ITeam[] | null>(null);
  const [showPDF, setShowPDF] = useState<boolean>(false);

  const componentRef = useRef<any>();

  const onExportAll = useCallback(async () => {
    const classes = await getClasses();
    const teams = await getTeams();
    const persons = await getPersons();
    const tractors = await getTractors();
    const drives = await getDrives();
    if (persons) {
      persons.sort((a, b) => {
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }
    if (teams) {
      teams.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }
    setPersons(persons);
    setTractors(tractors);
    setDrives(drives);
    setClasses(classes);
    setTeams(teams);
    setShowPDF(true);
  }, []);

  if (showPDF) {
    return (
      <div>
        <ReactToPrint
          content={() => componentRef.current}
          trigger={() => (
            <div
              style={{
                position: 'absolute',
                top: '5vw',
                right: '10vw',
              }}
            >
              <Button>PDF</Button>
            </div>
          )}
        />
        <div ref={componentRef}>
          <Summary
            persons={persons}
            teams={teams}
            tractors={tractors}
            drives={drives}
            classes={classes}
            setShowPDF={setShowPDF}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="home">
      <Card style={{ width: '18rem' }} onClick={onExportAll}>
        <Card.Body>
          <Card.Title>Zusammenfassung</Card.Title>
        </Card.Body>
      </Card>
      {Object.keys(cards).map((card) => (
        <Link key={card} to={`/${card}`}>
          <Card key={card} style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>{card}</Card.Title>
              <Card.Text>
                <div
                  dangerouslySetInnerHTML={{
                    __html: cards[card],
                  }}
                />
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
      ))}
      <Link to={`/currentDrives?line=1`}>
        <Card style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>Bahn 1</Card.Title>
          </Card.Body>
        </Card>
      </Link>
      <Link to={`/currentDrives?line=2`}>
        <Card style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>Bahn 2</Card.Title>
          </Card.Body>
        </Card>
      </Link>
      <Link to={`/showDrive?line=1`}>
        <Card style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>Anzeige Bahn 1</Card.Title>
          </Card.Body>
        </Card>
      </Link>
      <Link to={`/showDrive?line=2`}>
        <Card style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>Anzeige Bahn 2</Card.Title>
          </Card.Body>
        </Card>
      </Link>
      <Link to={`/showEditDrive?line=1`}>
        <Card style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>Bearbeiten Bahn 1</Card.Title>
          </Card.Body>
        </Card>
      </Link>
      <Link to={`/showEditDrive?line=2`}>
        <Card style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>Bearbeiten Bahn 2</Card.Title>
          </Card.Body>
        </Card>
      </Link>
    </div>
  );
};

export default Home;
