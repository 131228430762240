import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, CloseButton, FormControl, InputGroup, Table } from 'react-bootstrap';

import { getClasses } from '@/api/class';
import { getDrives, patchDrive } from '@/api/drive';
import { getPersons } from '@/api/person';
import { getTeams } from '@/api/team';
import { getTractors } from '@/api/tractor';
import { IClass } from '@/interfaces/class.interfaces';
import { IDrive } from '@/interfaces/drive.interfaces';
import { IPerson } from '@/interfaces/person.interfaces';
import { ITeam } from '@/interfaces/team.interfaces';
import { ITractor } from '@/interfaces/tractor.interfaces';

const columns = {
  teamName: 'Team',
  personName: 'Fahrer',
  tractorName: 'Trecker',
  startNumber: 'Startnummer',
  weight: 'Gewicht',
};

let timeout: any = null;

const Weighing = () => {
  const [classFilterId, setClassFilterId] = useState<string | null>(null);
  const [persons, setPersons] = useState<IPerson[] | null>(null);
  const [tractors, setTractors] = useState<ITractor[] | null>(null);
  const [drives, setDrives] = useState<IDrive[] | null>(null);
  const [classes, setClasses] = useState<IClass[] | null>(null);
  const [teams, setTeams] = useState<ITeam[] | null>(null);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    getPersons().then((data: IPerson[] | null) => {
      if (data) {
        setPersons(data);
      }
    });
    getDrives().then((data: IDrive[] | null) => {
      if (data) {
        setDrives(data.filter((x) => x.payed));
      }
    });
    getTractors().then((data: ITractor[] | null) => {
      if (data) {
        setTractors(data);
      }
    });
    getClasses().then((data: IClass[] | null) => {
      if (data) {
        setClasses(data);
      }
    });
    getTeams().then((data: ITeam[] | null) => {
      if (data) {
        setTeams(data);
      }
    });
  }, []);

  const data = useMemo(() => {
    let result = drives?.map((drive) => {
      const person = persons?.find((p) => p._id.toString() === drive.personId.toString());
      return {
        ...drive,
        teamName: teams?.find(
          (x) =>
            x._id === persons?.find((p) => p._id.toString() === drive.personId.toString())?.teamId
        )?.name,
        personName: person?.firstName + ' ' + person?.lastName,
        tractorName: tractors?.find((t) => t._id.toString() === drive.tractorId.toString())?.name,
      };
    });
    if (classFilterId && result) {
      result = result.filter((x) => x.classId === classFilterId);
    }
    if (search.length > 0 && result) {
      return result.filter((drive) => {
        const keys = Object.keys(columns);
        let included = false;
        keys.forEach((key) => {
          const value = drive[key];
          if (
            !included &&
            typeof value !== 'undefined' &&
            value.toString().toLowerCase().includes(search.toLowerCase())
          ) {
            included = true;
          }
        });
        return included;
      });
    }
    return result;
  }, [classFilterId, drives, persons, search, teams, tractors]);

  // TODO add server call
  const onChangeWeight = useCallback(
    (id, value) => {
      if (drives) {
        const newDrives = [...drives];
        const updateDrives = newDrives.map((x) => {
          if (x._id === id) {
            if (timeout) {
              clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
              patchDrive(id, {
                ...x,
                weight: value,
              }).then();
            }, 1500);
            return {
              ...x,
              weight: value,
            };
          }
          return x;
        });
        setDrives(updateDrives);
      }
    },
    [drives]
  );

  return (
    <div style={{ margin: '1vw 10vw', textAlign: 'center' }}>
      <h1>Waage</h1>
      <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap', margin: '10px' }}>
        {classes &&
          classes
            .filter((x) => x.enableWeighing)
            .map((c) => (
              <Button
                key={c._id}
                onClick={() => setClassFilterId(classFilterId === c._id ? null : c._id)}
                style={classFilterId === c._id ? { backgroundColor: 'red' } : undefined}
              >
                {c.name}
              </Button>
            ))}
      </div>
      {`${data?.filter((x) => x.weight).length}/${data?.length}`}
      {classFilterId && (
        <InputGroup className="mb-3">
          <FormControl
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
            placeholder="Suchen"
            aria-label="search"
          />
          <Button
            variant="outline-secondary"
            id="button-addon2"
            onClick={() => {
              setSearch('');
            }}
          >
            <CloseButton />
          </Button>
        </InputGroup>
      )}
      {classFilterId && (
        <Table striped bordered hover>
          <thead>
            <tr>
              {Object.keys(columns).map((column) => (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <th key={column}>{columns[column]}</th>
              ))}
            </tr>
          </thead>
          <tbody id="">
            {data &&
              data.map((entry: any) => (
                <tr key={entry._id}>
                  {Object.keys(columns).map((column) => {
                    if (column === 'weight') {
                      return (
                        <td
                          key={column}
                          style={{
                            width: '100%',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <FormControl
                            value={entry[column] || 0}
                            placeholder=""
                            aria-label="weight"
                            onChange={(e) => onChangeWeight(entry._id, e.target.value)}
                            style={{
                              width: '80px',
                              textAlign: 'center',
                            }}
                          />
                        </td>
                      );
                    }
                    return <td key={column}>{entry[column] || 0}</td>;
                  })}
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default Weighing;
