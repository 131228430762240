import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Dropdown,
  Modal,
  Table,
  FormControl,
  InputGroup,
  CloseButton,
} from 'react-bootstrap';

import './crud.scss';
import DataEdit from '@/components/DataEdit/DataEdit';
import DataPagination from '@/components/DataPagination/DataPagination';
import { sortRoomDescAlphaNumeric } from '@/utils/sort';

export interface ICrudProps {
  routeName: string;
  columns: {
    name: string;
    textKey: string;
    inputType?: string;
    values?: { value: string; name: string }[];
    refTextKey?: string;
    canEdit?: boolean;
    selectionBasedValues?: (data: any) => { value: string; name: string }[];
    required?: boolean;
    isArray?: boolean;
    onSelect?: (oldValue: string | undefined, newValue: string | undefined, data: any) => void;
    getNode?: (prop: any) => React.ReactNode;
  }[];
  disableCreate?: boolean;
  onDelete?: (prop: any) => void;
  onUpdate?: (prop: any) => void;
  onCreate?: (prop: any) => void;
  data: any;
  filterNode?: ReactNode;
}

const pageSize = 20;

const Crud: FC<ICrudProps> = ({
  routeName,
  columns,
  onDelete,
  onUpdate,
  onCreate,
  disableCreate = false,
  data,
  filterNode,
}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [entry, setEntry] = useState<object | null>(null);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [sortKey, setSortKey] = useState<string>(columns[0].textKey);
  const [sortAsc, setSortAsc] = useState<boolean>(false);
  const [secureValue, setSecureValue] = useState('');

  const handleClose = useCallback(() => {
    setEntry(null);
    setShowAdd(false);
    setShowEdit(false);
    setShowDelete(false);
  }, []);
  const handleShowAdd = useCallback(() => {
    const defaultEntry: any = {};
    columns.map((column) => {
      defaultEntry[column.textKey] = '';
    });
    setEntry(defaultEntry);
    setShowAdd(true);
  }, [columns]);
  const handleShowEdit = useCallback((entry: any) => {
    setEntry(entry);
    setShowEdit(true);
  }, []);
  const handleShowDelete = useCallback((entry: any) => {
    setEntry(entry);
    setShowDelete(true);
  }, []);
  const handleDelete = useCallback(async () => {
    setSecureValue('');
    if (onDelete) {
      await onDelete(entry);
    }
    handleClose();
  }, [handleClose, entry, onDelete]);
  // const onHandleChange = useCallback(
  //   (key: string, value: string) => {
  //     if (!entry) return;
  //     const newEntry: any = {
  //       ...entry,
  //     };
  //     newEntry[key] = value;
  //     setEntry(newEntry);
  //   },
  //   [entry]
  // );
  const onHandleSort = useCallback(
    (key) => {
      if (key === sortKey) {
        setSortAsc(!sortAsc);
      } else {
        setSortAsc(true);
        setSortKey(key);
      }
    },
    [sortAsc, sortKey]
  );

  const onSubmit = useCallback(
    async (data) => {
      if (showAdd && onCreate) {
        await onCreate(data);
      } else if (showEdit && onUpdate) {
        await onUpdate(data);
      }
      setShowAdd(false);
      setShowEdit(false);
      setShowDelete(false);
      setEntry(null);
      location.reload();
    },
    [onCreate, onUpdate, showAdd, showEdit]
  );

  const breadCrumbText = useMemo(() => {
    if (showAdd) return 'Hinzufügen';
    if (showEdit) return 'Bearbeiten';
    return 'Data';
  }, [showAdd, showEdit]);

  const searchData = useMemo(() => {
    let d = [...data];
    if (search) {
      const columnValues: string[] = [];
      columns.forEach((column) => {
        if (column.values && column.values.length > 0) {
          columnValues.push(
            ...column.values
              .filter((e) => e.name && e.name.toLowerCase().includes(search.toLowerCase()))
              .map((x) => x.value)
          );
        }
      });
      const entries: string[] = [];
      d.forEach((e: any) => {
        const columnEntries = columns.map((column) => e[column.textKey]);
        columnEntries.forEach((entry) => {
          if (entry && typeof entry === 'string' && columnValues.includes(entry)) {
            entries.push(e._id);
          }
        });
      });
      d = d.filter(
        (e: any) =>
          (e.name && e.name.toLowerCase().includes(search.toLowerCase())) ||
          (e.firstName && e.firstName.toLowerCase().includes(search.toLowerCase())) ||
          (e.lastName && e.lastName.toLowerCase().includes(search.toLowerCase())) ||
          (e.email && e.email.toLowerCase().includes(search.toLowerCase())) ||
          (e.startNumber &&
            e.startNumber.toString().toLowerCase().includes(search.toLowerCase())) ||
          entries.includes(e._id)
      );
    }
    return d;
  }, [columns, data, search]);

  const pageData = useMemo(() => {
    let d = search.length > 0 ? [...searchData] : [...data];
    d.sort((a, b) => sortRoomDescAlphaNumeric(a, b, sortKey, sortAsc));
    if (d.length > pageSize) {
      const results: any[] = [];
      for (let i = pageSize * (page - 1); i < pageSize * page; i++) {
        if (d[i]) {
          results.push(d[i]);
        }
      }
      d = results;
    }
    return d;
  }, [data, page, search.length, searchData, sortAsc, sortKey]);

  return (
    <div className="crud-container">
      <h1>{routeName}</h1>
      <div className="crud-breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href={`/${routeName.toLowerCase()}`}>{routeName}</Breadcrumb.Item>
          <Breadcrumb.Item active>{breadCrumbText}</Breadcrumb.Item>
        </Breadcrumb>
        {!disableCreate && !showAdd && !showEdit && (
          <Button onClick={handleShowAdd}>Hinzufügen</Button>
        )}
      </div>
      {!showEdit && !showAdd && (
        <div className="crud-table">
          <InputGroup className="mb-3">
            <FormControl
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(1);
              }}
              value={search}
              placeholder="Suchen"
              aria-label="search"
            />
            <Button
              variant="outline-secondary"
              id="button-addon2"
              onClick={() => {
                setSearch('');
                setPage(1);
              }}
            >
              <CloseButton />
            </Button>
          </InputGroup>
          {filterNode}
          <Table striped bordered hover>
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column.textKey} onClick={() => onHandleSort(column.textKey)}>
                    {column.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {pageData.map((entry: any) => (
                <tr key={entry._id}>
                  {columns.map((column) => {
                    if (!column || !entry) {
                      return null;
                    }
                    let value = entry[column.textKey];
                    if (column.values) {
                      const valueEntry = column.values.find((x) => x.value === value);
                      if (valueEntry) {
                        value = valueEntry.name;
                      }
                    }
                    return (
                      <td key={column.textKey}>
                        {value !== null && typeof value !== 'undefined' ? value.toString() : ''}
                      </td>
                    );
                  })}
                  {(onUpdate || onDelete) && (
                    <td>
                      <Dropdown key="dropdown">
                        <Dropdown.Toggle variant="success" id="dropdown-basic"></Dropdown.Toggle>
                        <Dropdown.Menu>
                          {onUpdate && (
                            <Dropdown.Item onClick={() => handleShowEdit(entry)}>
                              Bearbeiten
                            </Dropdown.Item>
                          )}
                          {onDelete && (
                            <Dropdown.Item onClick={() => handleShowDelete(entry)}>
                              Löschen
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          {((data.length > pageSize && search.length === 0) ||
            (searchData.length > pageSize && search.length > 0)) && (
            <DataPagination
              active={page}
              count={(search.length > 0 ? searchData.length : data.length) / pageSize}
              onChange={setPage}
            />
          )}
        </div>
      )}
      {(showEdit || showAdd) && entry && (
        <div>
          <DataEdit
            entry={entry}
            onSave={(data) => onSubmit(data)}
            columns={columns
              .filter((x) => x.textKey && x.inputType)
              .map((x) => ({
                name: x.name,
                textKey: x.textKey,
                inputType: x.inputType || '',
                values: x.values,
                refTextKey: x.refTextKey,
                canEdit: x.canEdit,
                selectionBasedValues: x.selectionBasedValues,
                required: x.required,
                isArray: x.isArray,
                onSelect: x.onSelect,
              }))}
          />
        </div>
      )}
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{routeName} löschen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`Möchtest Du wirklich den Eintrag löschen?`}
          <FormControl
            onChange={(e) => {
              setSecureValue(e.target.value);
            }}
            value={secureValue}
            placeholder="PIN"
            aria-label="PIN"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={secureValue !== 'pelikan'} onClick={handleDelete}>
            Löschen
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Crud;
