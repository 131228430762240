export const setLocalStorage = (key: string, value: any) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (error) {
    console.error('error while set localstorage', key, value, error);
  }
};

export const getLocalStorage = (key: string) => {
  try {
    return window.localStorage.getItem(key);
  } catch (error) {
    console.error('error while get localstorage', key, error);
  }
  return null;
};

export const removeLocalStorageKey = (key: string) => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    console.error('error while remove localstorage key', key, error);
  }
};
