import { IPerson } from '@/interfaces/person.interfaces';
import axios from '@/lib/axios';

export const getPersons = async (): Promise<IPerson[] | null> => {
  try {
    const response = await axios.get('person');
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const getPersonById = async (id: string): Promise<IPerson | null> => {
  try {
    const response = await axios.get(`person/${id}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const createPerson = async (data: any): Promise<IPerson | null> => {
  try {
    const response = await axios.post('person', data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const patchPerson = async (id: string, data: any): Promise<IPerson | null> => {
  try {
    const response = await axios.patch(`person/${id}`, data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const deletePerson = async (id: string): Promise<boolean> => {
  try {
    const response = await axios.delete(`person/${id}`);
    return response.status === 200;
  } catch (error) {
    console.log(error);
  }
  return false;
};
