import React, { FC, useMemo } from 'react';
import { Pagination } from 'react-bootstrap';

interface IDataPaginationProps {
  count: number;
  active: number;
  onChange: (page: number) => void;
}

interface IItem {
  active?: boolean;
  number: number;
}

const DataPagination: FC<IDataPaginationProps> = ({ count, active, onChange }) => {
  const items = useMemo(() => {
    const results: IItem[] = [];
    for (let i = 0; i < count; i++) {
      results.push({
        active: i + 1 === active,
        number: i + 1,
      });
    }
    return results;
  }, [active, count]);

  return (
    <Pagination>
      <Pagination.First disabled={active === 1} onClick={() => onChange(1)} />
      <Pagination.Prev disabled={active === 1} onClick={() => onChange(1)} />
      {items.map((item) => (
        <Pagination.Item
          key={item.number}
          active={item.active}
          onClick={() => onChange(item.number)}
        >
          {item.number}
        </Pagination.Item>
      ))}
      <Pagination.Next disabled={active === items.length} onClick={() => onChange(items.length)} />
      <Pagination.Last disabled={active === items.length} onClick={() => onChange(items.length)} />
    </Pagination>
  );
};

export default DataPagination;
