/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, CloseButton, FormControl, InputGroup, Table } from 'react-bootstrap';

import { getClasses } from '@/api/class';
import { getDrives } from '@/api/drive';
import { getPersons } from '@/api/person';
import { getTeams } from '@/api/team';
import { getTractors } from '@/api/tractor';
import { IClass } from '@/interfaces/class.interfaces';
import { IDrive } from '@/interfaces/drive.interfaces';
import { IPerson } from '@/interfaces/person.interfaces';
import { ITeam } from '@/interfaces/team.interfaces';
import { ITractor } from '@/interfaces/tractor.interfaces';

const TeamOverview = () => {
  const [persons, setPersons] = useState<IPerson[] | null>(null);
  const [tractors, setTractors] = useState<ITractor[] | null>(null);
  const [drives, setDrives] = useState<IDrive[] | null>(null);
  const [classes, setClasses] = useState<IClass[] | null>(null);
  const [teams, setTeams] = useState<ITeam[] | null>(null);
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);
  const [search, setSearch] = useState<string>('');

  const selectedTeam = useMemo(() => {
    if (selectedTeamId) {
      return teams?.find((team) => team._id === selectedTeamId) || null;
    }
    return null;
  }, [selectedTeamId, teams]);

  useEffect(() => {
    getPersons().then((data: IPerson[] | null) => {
      if (data) {
        setPersons(data);
      }
    });
    getDrives().then((data: IDrive[] | null) => {
      if (data) {
        setDrives(data);
      }
    });
    getTractors().then((data: ITractor[] | null) => {
      if (data) {
        setTractors(data);
      }
    });
    getClasses().then((data: IClass[] | null) => {
      if (data) {
        setClasses(data);
      }
    });
    getTeams().then((data: ITeam[] | null) => {
      if (data) {
        setTeams(data);
      }
    });
  }, []);

  return (
    <div style={{ margin: '10vw' }}>
      <h1>Teamübersicht</h1>
      <InputGroup className="mb-3">
        <FormControl
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          value={search}
          placeholder="Suchen"
          aria-label="search"
        />
        <Button
          variant="outline-secondary"
          id="button-addon2"
          onClick={() => {
            setSearch('');
          }}
        >
          <CloseButton />
        </Button>
      </InputGroup>
      {selectedTeam === null && (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {(search.length > 0
            ? teams?.filter((x) => x.name.toLowerCase().includes(search.toLowerCase())) || []
            : teams || []
          ).map((team) => (
            <Card key={team._id} onClick={() => setSelectedTeamId(team._id)}>
              <Card.Body>
                <Card.Title>{team.name}</Card.Title>
              </Card.Body>
            </Card>
          ))}
        </div>
      )}
      {selectedTeam !== null && (
        <div>
          <div
            style={{
              position: 'absolute',
              top: '10vw',
              right: '10vw',
            }}
          >
            <Button onClick={() => setSelectedTeamId(null)}>Zurück</Button>
          </div>
          <h2>{selectedTeam.name}</h2>
          <div>{selectedTeam.description}</div>
          <div>Anzahl Frühstück {selectedTeam.breakFirstPersonCount}</div>
          <div style={{ marginTop: '30px' }}>
            {classes?.map((classe) => {
              const teamPersons = persons?.filter((x) => x.teamId === selectedTeam._id) || [];
              const personIds = teamPersons.map((person) => person._id);
              const classDrives =
                drives?.filter((x) => x.classId === classe._id && personIds.includes(x.personId)) ||
                [];
              if (classDrives.length === 0) {
                return null;
              }
              return (
                <div key={classe._id}>
                  <h3>{classe.name}</h3>
                  <div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Startnummer</th>
                        </tr>
                      </thead>
                      <tbody>
                        {classDrives.map((drive) => {
                          const person = teamPersons.find((x) => drive.personId === x._id);
                          return (
                            <tr key={drive._id}>
                              <td key={drive._id}>
                                {person ? person.firstName + ' ' + person.lastName : ''}
                              </td>
                              <td key={drive._id}>{drive.startNumber}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamOverview;
