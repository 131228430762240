/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';

import { getClasses } from '@/api/class';
import { getDrives } from '@/api/drive';
import { getPersons } from '@/api/person';
import { getTeams } from '@/api/team';
import { getTractors } from '@/api/tractor';
import { IClass } from '@/interfaces/class.interfaces';
import { IDrive } from '@/interfaces/drive.interfaces';
import { IPerson } from '@/interfaces/person.interfaces';
import { IRankingData, IRankingDrive } from '@/interfaces/stats.interfaces';
import { ITeam } from '@/interfaces/team.interfaces';
import { ITractor } from '@/interfaces/tractor.interfaces';

const columns = {
  position: 'Platz',
  startNumber: 'Startnummer',
  teamName: 'Team',
  personName: 'Fahrer',
  tractorName: 'Trecker',
  distances: 'Strecke',
  'distances.length': 'Versuche',
  power: 'Leistung',
};

const Statistic = () => {
  const [persons, setPersons] = useState<IPerson[] | null>(null);
  const [tractors, setTractors] = useState<ITractor[] | null>(null);
  const [drives, setDrives] = useState<IDrive[] | null>(null);
  const [classes, setClasses] = useState<IClass[] | null>(null);
  const [teams, setTeams] = useState<ITeam[] | null>(null);

  useEffect(() => {
    getPersons().then((data: IPerson[] | null) => {
      if (data) {
        setPersons(data);
      }
    });
    getDrives().then((data: IDrive[] | null) => {
      if (data) {
        setDrives(data.filter((x) => x.distances.length > 0));
      }
    });
    getTractors().then((data: ITractor[] | null) => {
      if (data) {
        setTractors(data);
      }
    });
    getClasses(true).then((data: IClass[] | null) => {
      if (data) {
        setClasses(data);
      }
    });
    getTeams().then((data: ITeam[] | null) => {
      if (data) {
        setTeams(data);
      }
    });
    window.document.title = 'Trecker Treck Statistik';
  }, []);

  const driverCount = useMemo(() => {
    const count: string[] = [];
    drives?.forEach((x) => {
      if (!count.includes(x.personId)) {
        count.push(x.personId);
      }
    });
    return count.length;
  }, [drives]);
  const distanceSum = useMemo(() => {
    let sum = 0;
    drives?.forEach((x) => {
      x.distances.forEach((d) => {
        sum += parseFloat(String(d));
      });
    });
    return sum;
  }, [drives]);
  const countFP = useMemo(() => {
    let sum = 0;
    drives?.forEach((x) => {
      x.distances.forEach((d) => {
        if (parseFloat(String(d)) === 100) {
          sum++;
        }
      });
    });
    return sum;
  }, [drives]);

  const data = useMemo(() => {
    const results: IRankingData[] = [];
    classes?.forEach((classe) => {
      const classDrives: any[] = [];
      drives
        ?.filter((x) => x.classId === classe._id)
        .forEach((drive) => {
          const tractor = tractors?.find((x) => x._id.toString() === drive.tractorId.toString());
          const person = persons?.find((x) => x._id.toString() === drive.personId.toString());
          const team = teams?.find((x) => x._id.toString() === person?.teamId.toString());
          if (tractor && person && team && classe) {
            classDrives.push({
              id: drive._id.toString(),
              teamName: team.name,
              tractorName: tractor.name,
              personName: person.firstName + ' ' + person.lastName,
              distances: drive.distances || [],
              weight: drive.weight || 0,
              startNumber: drive.startNumber || 0,
              power: tractor.power || 0,
            });
          }
        });
      classDrives.sort((a, b) => {
        if (a.distances.length < b.distances.length) {
          return 1;
        }
        if (a.distances.length > b.distances.length) {
          return -1;
        }
        let aSum = 0;
        a.distances.forEach((x) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          aSum += parseFloat(x);
        });
        let bSum = 0;
        b.distances.forEach((x) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          bSum += parseFloat(x);
        });
        if (a.class === 'Freie Klasse') {
          if (aSum > 100 || bSum > 100) {
            console.log('aSum: ', aSum);
            console.log('bSum: ', bSum);
          }
        }
        if (aSum < bSum) {
          return 1;
        }
        if (aSum > bSum) {
          return -1;
        }
        return 0;
      });
      results.push({
        className: classe.name,
        drives: classDrives.map((entry, index) => ({
          ...entry,
          position: index + 1,
        })),
      });
    });

    results.sort((a, b) => {
      if (isNaN(parseFloat(a.className))) {
        return 1;
      }
      if (isNaN(parseFloat(b.className))) {
        return -1;
      }
      const aNumber = parseFloat(a.className.replace(',', '.'));
      const bNumber = parseFloat(b.className.replace(',', '.'));
      if (aNumber > bNumber) {
        return 1;
      }
      if (aNumber < bNumber) {
        return -1;
      }
      if (a.className.length > b.className.length) {
        return 1;
      }
      if (a.className.length < b.className.length) {
        return -1;
      }
      return 0;
    });
    return results;
  }, [classes, drives, persons, teams, tractors]);

  return (
    <div style={{ margin: '5vh 10vw' }}>
      <h1>Statistik</h1>
      <div>
        <div>{`Fahrer: ${driverCount}`}</div>
        <div>{`Fahrten: ${drives?.length || 0}`}</div>
        <div>{`Gesamte Strecke: ${Math.round(distanceSum)} m`}</div>
        <div>{`Full Pulls: ${countFP}`}</div>
        {data.map((classData) => (
          <div key={classData.className}>
            <h3>{classData.className}</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {Object.keys(columns).map((column) => (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <th key={column}>{columns[column]}</th>
                  ))}
                </tr>
              </thead>
              <tbody id="">
                {classData.drives.map((entry: any) => (
                  <tr key={entry._id}>
                    {Object.keys(columns).map((column) => {
                      let value = entry[column] || 0;
                      if (column === 'distances') {
                        value = value
                          .map((x) => parseFloat(x))
                          .reduce((pv, cv) => pv + cv, 0)
                          .toString()
                          .replace('.', ',');
                      } else if (column === 'distances.length' && entry['distances']) {
                        value = entry['distances'].length;
                      }
                      return <td key={column}>{value}</td>;
                    })}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Statistic;
