import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './class.scss';

import { createClass, deleteClass, getClasses, patchClass } from '@/api/class';
import Crud from '@/components/crud/Crud';
import { IClass } from '@/interfaces/class.interfaces';

const columns = [
  {
    name: 'Name',
    textKey: 'name',
    inputType: 'string',
  },
  {
    name: 'Kommentar',
    textKey: 'comment',
    inputType: 'string',
  },
  {
    name: 'Anzahl',
    textKey: 'count',
    inputType: 'number',
  },
  {
    name: 'Freigabe Waage',
    textKey: 'enableWeighing',
    inputType: 'boolean',
  },
  {
    name: 'Deaktiviert',
    textKey: 'disabled',
    inputType: 'boolean',
  },
];

const Class = () => {
  const [classes, setClasses] = useState<IClass[] | null>(null);

  useEffect(() => {
    getClasses(true).then((data: IClass[] | null) => {
      if (data) {
        setClasses([...data]);
      }
    });
  }, []);

  const onDelete = useCallback(
    async (entry: IClass) => {
      if (classes && (await deleteClass(entry._id))) {
        setClasses(classes.filter((c) => c._id !== entry._id));
      }
    },
    [classes]
  );

  const onUpdate = useCallback(async (entry: IClass) => {
    await patchClass(entry._id, entry);
  }, []);

  const onCreate = useCallback(async (entry: IClass) => {
    await createClass({
      name: entry.name,
      comment: entry.comment || '',
      count: entry.count || 0,
      enableWeighing: entry.enableWeighing || false,
      disabled: entry.disabled || false,
    });
  }, []);

  const data = useMemo(
    () =>
      classes?.map((classe) => ({
        ...classe,
        enableWeighing: classe.enableWeighing || false,
      })),
    [classes]
  );

  return (
    <div className="class">
      {data && (
        <Crud
          routeName="Class"
          data={data}
          columns={columns}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      )}
    </div>
  );
};

export default Class;
