/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, FormControl, Table } from 'react-bootstrap';

import { getClassById } from '@/api/class';
import { getDriveById, patchDrive } from '@/api/drive';
import { getPersonById } from '@/api/person';
import { getTeamById } from '@/api/team';
import { getTractorById } from '@/api/tractor';
import { LineSocketContext } from '@/context/socket';
import useRunningDrives from '@/hooks/runningDrives';
import { IClass } from '@/interfaces/class.interfaces';
import { IDrive } from '@/interfaces/drive.interfaces';
import { IPerson } from '@/interfaces/person.interfaces';
import { ITeam } from '@/interfaces/team.interfaces';
import { ITractor } from '@/interfaces/tractor.interfaces';

export const getLineNumber = () => {
  const href = window.location.href;
  const splitted = href.split('?');
  return splitted.length < 2 ? null : parseInt(splitted[1].replace('line=', ''), 10);
};

interface IShowCurrentDrive {
  showEdit?: boolean;
}

export const ShowCurrentDrive: FC<IShowCurrentDrive> = ({ showEdit }) => {
  const { runningDrives } = useRunningDrives();
  const lineNumber = getLineNumber();
  const socket = useContext(LineSocketContext);
  const [person, setPerson] = useState<IPerson | null>(null);
  const [tractor, setTractor] = useState<ITractor | null>(null);
  const [drive, setDrive] = useState<IDrive | null>(null);
  const [classe, setClasse] = useState<IClass | null>(null);
  const [team, setTeam] = useState<ITeam | null>(null);
  const [distance, setDistance] = useState('');

  useEffect(() => {
    if (lineNumber) {
      const drives = runningDrives?.filter((x) => x.line === lineNumber);
      if (drives.length > 0) {
        const driveId = drives[0].driveId;
        getDriveById(driveId).then((data) => {
          if (!data) {
            return;
          } else {
            setDrive(data);
            getClassById(data.classId).then((c) => {
              setClasse(c);
            });
            getTractorById(data.tractorId).then((t) => {
              setTractor(t);
            });
            getPersonById(data.personId).then((p) => {
              setPerson(p);
              if (p) {
                getTeamById(p.teamId).then((t) => {
                  setTeam(t);
                });
              }
            });
          }
        });
        return;
      }
    }
    setPerson(null);
    setTractor(null);
    setDrive(null);
    setClasse(null);
    setTeam(null);
  }, [lineNumber, runningDrives]);

  if (!drive) {
    return (
      <div style={{ margin: '1vw 10vw', textAlign: 'center' }}>
        <h1>{`Spur Nummer ${lineNumber}`}</h1>
        <h1>Warten</h1>
      </div>
    );
  }

  return (
    <div style={{ margin: '1vw 10vw', textAlign: 'center' }}>
      <h1>{`Spur Nummer ${lineNumber}`}</h1>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Table
          striped
          bordered
          hover
          style={{ minWidth: '200px', width: '25%', maxWidth: '600px', textAlign: 'center' }}
        >
          <thead>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody id="">
            <tr>
              <td style={{ width: '150px' }}>StartNummer</td>
              <td>{drive.startNumber}</td>
            </tr>
            <tr>
              <td>Vorname</td>
              <td>{person?.firstName}</td>
            </tr>
            <tr>
              <td>Nachname</td>
              <td>{person?.lastName}</td>
            </tr>
            {person?.city && (
              <tr>
                <td>Stadt</td>
                <td>{person?.city}</td>
              </tr>
            )}
            {person?.description && (
              <tr>
                <td>Personbeschreibung</td>
                <td>{person?.description}</td>
              </tr>
            )}
            <tr>
              <td>Trecker</td>
              <td>{tractor?.name}</td>
            </tr>
            <tr>
              <td>PS</td>
              <td>{tractor?.power}</td>
            </tr>
            {tractor?.year && (
              <tr>
                <td>Baujahr</td>
                <td>{tractor?.year}</td>
              </tr>
            )}
            <tr>
              <td>Team</td>
              <td>{team?.name}</td>
            </tr>
            {team?.description && (
              <tr>
                <td>Teambeschreibung</td>
                <td>{team?.description}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {showEdit && (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
          <FormControl
            value={distance}
            placeholder="Strecke"
            aria-label="weight"
            onChange={(e) => setDistance(e.target.value)}
            style={{
              width: '150px',
              textAlign: 'center',
            }}
          />
          <Button
            onClick={async () => {
              setDistance('');
              await patchDrive(drive._id, {
                personId: drive.personId,
                tractorId: drive.tractorId,
                classId: drive.classId,
                teamId: drive.teamId,
                weight: drive.weight,
                startNumber: drive.startNumber,
                distances: drive.distances
                  ? [...drive.distances, parseFloat(distance)]
                  : [parseFloat(distance)],
                payed: drive.payed,
              });
              setDistance('');
              socket.emit('updatedDistance', {
                driveId: drive._id,
                line: lineNumber,
              });
            }}
          >
            Speichern
          </Button>
        </div>
      )}
    </div>
  );
};
