/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Transition } from 'react-transition-group';

import './App.scss';

import logo from '@/assets/logo.svg';
import Class from '@/components/class/Class';
import Class2New from '@/components/class2New/Class2New';
import Drive from '@/components/drive/Drive';
import Home from '@/components/home/Home';
import KioskMode from '@/components/kisokMode/KioskMode';
import Person from '@/components/person/Person';
import Ranking from '@/components/ranking/Ranking';
import RankingOverview from '@/components/ranking/RankingOverview';
import Registration from '@/components/registration/Registration';
import Statistic from '@/components/statistic/Statistic';
import Team from '@/components/team/Team';
import TeamOverview from '@/components/teamOverview/teamOverview';
import Tractor from '@/components/tractor/Tractor';
import Weighing from '@/components/weighing/Weighing';
import { CurrentDrives } from '@/features/currentDrives/CurrentDrives';
import { ShowCurrentDrive } from '@/features/currentDrives/ShowCurrentDrive';
import LineSocketProvider from '@/providers/LineSocketProvider';
import { getLocalStorage } from '@/utils/localStorage';

const ONLY_LOGIN = getLocalStorage('admin') !== 'true';
const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles: any = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

function App() {
  const [loading] = useState(false);

  useEffect(() => {
    // if (window.location.pathname === '/') {
    //   setLoading(true);
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 2000);
    // }
  }, []);

  return (
    <div>
      <Transition in={loading} timeout={duration}>
        {(state) => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: state === 'exited' ? 'none' : 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
              <img src={logo} alt="SVG as an image" />
              <h1>{`Trecker Treck ${new Date().getFullYear()}`}</h1>
            </div>
          </div>
        )}
      </Transition>
      <Transition in={!loading} timeout={duration}>
        {(state) => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <Routes>
              {!loading && ONLY_LOGIN && (
                <>
                  <Route path="/" element={<Registration />} />{' '}
                  <Route path="stats" element={<Ranking />} />
                  <Route path="statsOverview" element={<RankingOverview />} />
                </>
              )}
              {!loading && !ONLY_LOGIN && (
                <>
                  <Route path="/" element={<Home />} />
                  <Route path="class" element={<Class />} />
                  <Route path="drive" element={<Drive />} />
                  <Route path="person" element={<Person />} />
                  <Route path="team" element={<Team />} />
                  <Route path="tractor" element={<Tractor />} />
                  <Route path="stats" element={<Ranking />} />
                  <Route path="statsOverview" element={<RankingOverview />} />
                  <Route path="class2New" element={<Class2New />} />
                  <Route path="kioskMode" element={<KioskMode />} />
                  <Route path="weighing" element={<Weighing />} />
                  <Route path="teamOverview" element={<TeamOverview />} />
                  <Route path="statistic" element={<Statistic />} />
                  <Route
                    path="currentDrives"
                    element={
                      <LineSocketProvider>
                        <CurrentDrives />
                      </LineSocketProvider>
                    }
                  />
                  <Route
                    path="showDrive"
                    element={
                      <LineSocketProvider>
                        <ShowCurrentDrive />
                      </LineSocketProvider>
                    }
                  />
                  <Route
                    path="showEditDrive"
                    element={
                      <LineSocketProvider>
                        <ShowCurrentDrive showEdit />
                      </LineSocketProvider>
                    }
                  />
                </>
              )}
            </Routes>
          </div>
        )}
      </Transition>
    </div>
  );
}

export default App;
