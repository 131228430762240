import { ITeam } from '@/interfaces/team.interfaces';
import axios from '@/lib/axios';

export const getTeams = async (): Promise<ITeam[] | null> => {
  try {
    const response = await axios.get('team');
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const getTeamById = async (id: string): Promise<ITeam | null> => {
  try {
    const response = await axios.get<ITeam>(`team/${id}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const createTeam = async (data: any): Promise<ITeam | null> => {
  try {
    const response = await axios.post('team', data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const patchTeam = async (id: string, data: any): Promise<ITeam | null> => {
  try {
    const response = await axios.patch(`team/${id}`, data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const deleteTeam = async (id: string): Promise<boolean> => {
  try {
    const response = await axios.delete(`team/${id}`);
    return response.status === 200;
  } catch (error) {
    console.log(error);
  }
  return false;
};
