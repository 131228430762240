import { getClasses } from '@/api/class';
import { getDrives } from '@/api/drive';
import { getPersons } from '@/api/person';
import { IRankingData } from '@/interfaces/stats.interfaces';

import { getTeams } from './team';
import { getTractors } from './tractor';

export const getClassStats = async (
  classId: string,
  filterKey?: string
): Promise<IRankingData | null> => {
  try {
    const drives = await getDrives();
    const teams = await getTeams();
    const tractors = await getTractors();
    const persons = await getPersons();
    const classes = await getClasses();

    const classDrives = drives?.filter((x) => x.classId === classId) || [];

    const statsDrives = classDrives.map((drive) => {
      const tractor = tractors?.find((x) => x._id.toString() === drive.tractorId.toString());
      const person = persons?.find((x) => x._id.toString() === drive.personId.toString());
      const team = teams?.find((x) => x._id.toString() === person?.teamId.toString());
      return {
        id: drive._id.toString(),
        teamName: team?.name || '',
        tractorName: tractor?.name || '',
        personName: person?.firstName + ' ' + person?.lastName,
        startNumber: drive.startNumber || 0,
        distances: drive.distances || [],
        weight: drive.weight || 0,
        power: tractor?.power || 0,
      };
    });
    statsDrives.sort((a, b) => {
      if (a.distances.length < b.distances.length) {
        return 1;
      }
      if (a.distances.length > b.distances.length) {
        return -1;
      }
      let aSum = 0;
      a.distances.forEach((x) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        aSum += parseFloat(x);
      });
      let bSum = 0;
      b.distances.forEach((x) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        bSum += parseFloat(x);
      });
      if (aSum < bSum) {
        return 1;
      }
      if (aSum > bSum) {
        return -1;
      }
      if (filterKey) {
        if (a[filterKey] < b[filterKey]) {
          return 1;
        }
        if (a[filterKey] > b[filterKey]) {
          return -1;
        }
      }
      return 0;
    });
    return {
      className: classes?.find((x) => x._id.toString() === classId)?.name || '',
      drives: statsDrives.map((entry, index) => ({
        ...entry,
        position: index + 1,
      })),
    };
  } catch (error) {
    console.log(error);
  }
  return null;
};
