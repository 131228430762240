import { Context, createContext } from 'react';
import { io } from 'socket.io-client';
import { Socket } from 'socket.io-client/build/esm/socket';

import { SOCKET_URL } from '@/constants/api';

export const socket: Socket = io(SOCKET_URL, {
  secure: false,
});

export const SocketContext: Context<Socket> = createContext(socket);

export const lineSocket: Socket = io(SOCKET_URL, {
  secure: false,
  path: '/drives',
  forceNew: true,
});

export const LineSocketContext: Context<Socket> = createContext(lineSocket);
