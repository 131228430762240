/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';

import { createFinalRound, createFromClass, getClasses } from '@/api/class';
import { getDrives } from '@/api/drive';
import { getTractors } from '@/api/tractor';
import Ranking from '@/components/ranking/Ranking';
import { CreateFromClassDto, IClass } from '@/interfaces/class.interfaces';
import { IDrive } from '@/interfaces/drive.interfaces';
import { ITractor } from '@/interfaces/tractor.interfaces';

function berechneMedian(psList: number[]): number {
  if (psList.length === 0) return 0;

  const sortiert = [...psList].sort((a, b) => a - b);
  const mitte = Math.floor(sortiert.length / 2);

  if (sortiert.length % 2 === 0) {
    return (sortiert[mitte - 1] + sortiert[mitte]) / 2;
  } else {
    return sortiert[mitte];
  }
}

const Class2New = () => {
  const [classes, setClasses] = useState<IClass[] | null>(null);
  const [classFilterId, setClassFilterId] = useState<string | null>(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isTop, setIsTop] = useState<boolean>(false);
  const [isGreater, setIsGreater] = useState<boolean>(false);
  const [keepWeight, setKeepWeight] = useState<boolean>(false);
  const [keepDistance, setKeepDistance] = useState<boolean>(false);
  const [value, setValue] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [drives, setDrives] = useState<IDrive[] | null>(null);
  const [tractors, setTractors] = useState<ITractor[] | null>(null);

  const durchschnittPs = useMemo(() => {
    if (classFilterId && drives && tractors) {
      const classTractors: ITractor[] = [];
      drives.forEach((drive) => {
        const tractor = tractors.find(
          (t) => t._id === drive.tractorId && classFilterId === drive.classId
        );
        if (tractor) {
          classTractors.push(tractor);
        }
      });
      if (classTractors.length === 0) {
        return '';
      }
      const powers = classTractors.map((x) => x.power);
      const sum = berechneMedian(powers);
      return `(Mitte bei ${sum.toFixed(2).toString().replace('.', ',')} PS)`;
    }
    return '';
  }, [classFilterId, drives, tractors]);

  useEffect(() => {
    getDrives().then((data) => {
      setDrives(data);
    });
    getTractors().then((data) => {
      setTractors(data);
    });
  }, []);

  useEffect(() => {
    getClasses().then((data: IClass[] | null) => {
      if (data) {
        setClasses(data);
      }
    });
  }, []);

  const onClick = useCallback(async () => {
    if (classFilterId) {
      setLoading(true);
      const data: CreateFromClassDto = {
        name,
        comment: description,
        keepWeight,
        keepDistance,
        classId: classFilterId,
      };
      if (isTop) {
        data.top = value;
      } else {
        if (isGreater) {
          data.powerGreaterAs = value;
        } else {
          data.powerLessAs = value;
        }
      }
      const result = data.top ? await createFinalRound(data) : await createFromClass(data);
      setSuccess(result);
      setLoading(false);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  }, [classFilterId, description, isGreater, isTop, keepDistance, keepWeight, name, value]);

  return (
    <div style={{ padding: '10vh 10vw', textAlign: 'center' }}>
      <h1>Neue Klasse erstellen</h1>
      {showAlert && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 100,
          }}
        >
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: '#E5E5E5',
              opacity: '0.6',
            }}
          />
          <Alert variant={success ? 'success' : 'danger'}>
            {success ? 'Neue Klasse wurde erstellt' : 'Es ist ein Fehler aufgetreten'}
          </Alert>
        </div>
      )}
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: '#E5E5E5',
              opacity: '0.6',
            }}
          />
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          gap: '5px',
          flexWrap: 'wrap',
          margin: '10px',
          justifyContent: 'center',
        }}
      >
        {classes &&
          classes.map((c) => (
            <Button
              key={c._id}
              onClick={() => {
                setClassFilterId(classFilterId === c._id ? null : c._id);
                setName(classFilterId === c._id ? '' : c.name);
              }}
              style={classFilterId === c._id ? { backgroundColor: 'red' } : undefined}
            >
              {c.name}
            </Button>
          ))}
      </div>
      {classFilterId && (
        <div
          style={{
            gap: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '10px',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <Form.Control
              type="string"
              placeholder="Name eingeben"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{
                maxWidth: '300px',
              }}
            />
            <Form.Control
              type="string"
              placeholder="Beschreibung eingeben"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              style={{
                maxWidth: '300px',
              }}
            />
          </div>
          <div
            style={{
              width: '20%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '15px',
            }}
          >
            <Form.Check
              type="radio"
              id="topOrPower"
              checked={!isTop}
              label={`Leistung (PS) ${durchschnittPs}`}
              onChange={() => {
                setIsTop(false);
                setValue(undefined);
              }}
            />
            <Form.Check
              type="radio"
              id="topOrPower"
              label="Ranking"
              checked={isTop}
              onChange={() => {
                setIsTop(true);
                setValue(undefined);
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                width: '100vw',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {!isTop && (
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label={isGreater ? 'Größer als' : 'Kleiner als'}
                  checked={isGreater}
                  onChange={() => setIsGreater(!isGreater)}
                />
              )}
              <Form.Control
                type="number"
                placeholder={isTop ? 'Ersten Top X' : 'Leistung (PS)'}
                value={value || ''}
                onChange={(e) => setValue(parseInt(e.target.value, 10))}
                style={{
                  maxWidth: '200px',
                }}
              />
            </div>

            <Form.Check
              type="checkbox"
              id="keepWeight"
              label="Gewicht behalten"
              checked={keepWeight}
              onChange={() => {
                setKeepWeight(!keepWeight);
              }}
            />
            <Form.Check
              type="checkbox"
              id="keepDistance"
              label="Strecke behalten"
              checked={keepDistance}
              onChange={() => {
                setKeepDistance(!keepDistance);
              }}
            />
          </div>
          <div>
            <Button disabled={!value || value <= 0} onClick={onClick}>
              Neu erstellen
            </Button>
          </div>
        </div>
      )}
      {classFilterId && <Ranking classId={classFilterId} filterKey="power" />}
    </div>
  );
};

export default Class2New;
