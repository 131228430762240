import { FC, useContext, useEffect } from 'react';

import { LineSocketContext } from '@/context/socket';
import useRunningDrives, { IRunningDrive } from '@/hooks/runningDrives';

type LineSocketProviderProps = {
  children: JSX.Element;
};

const LineSocketProvider: FC<LineSocketProviderProps> = ({ children }) => {
  const socket = useContext(LineSocketContext);
  const { setRunningDrives } = useRunningDrives();

  useEffect(() => {
    socket.on('connect', () => {
      console.log('connected LineSocketProvider');
    });
    socket.on('disconnect', () => {
      console.log('disconnect LineSocketProvider');
    });
    socket.on('RUNNING_DRIVES', (driveIds: IRunningDrive[]) => {
      setRunningDrives(driveIds);
    });
    socket.on('connect_error', (err) => {
      console.log('connect_error LineSocketProvider', err);
    });
    return () => {
      socket.off('connect', () => {
        console.debug('connect (cleanup) LineSocketProvider');
      });

      socket.off('RUNNING_DRIVES', () => {
        console.debug('RUNNING_DRIVES (cleanup) LineSocketProvider');
      });
      socket.off('disconnect', () => {
        console.debug('Websocket disconnected (cleanup) LineSocketProvider');
      });
      socket.off('connect_error', () => {
        console.debug('connect_error (cleanup) LineSocketProvider');
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>{children}</div>;
};

export default LineSocketProvider;
