import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';

import { getLocalStorage } from '@/utils/localStorage';

export interface IEditColumn {
  name: string;
  textKey: string;
  inputType: string;
  values?: { value: string; name: string }[];
  refTextKey?: string;
  canEdit?: boolean;
  selectionBasedValues?: (data: any) => { value: string; name: string }[];
  required?: boolean;
  isArray?: boolean;
  onSelect?: (oldValue: string | undefined, newValue: string | undefined, data: any) => void;
}

export interface IDataEdit {
  columns: IEditColumn[];
  onSave: (value: object) => void;
  onDelete?: (value: object) => void;
  entry?: any;
}

const DataEdit: FC<IDataEdit> = ({ columns, onSave, entry, onDelete }) => {
  const [data, setData] = useState({});
  const [focusIndex, setFocusIndex] = useState<number | null>(null);
  const [searches, setSearches] = useState<{ textKey: string; value: string }[]>([]);

  const enableSearch = useMemo(() => {
    const search = getLocalStorage('enableSearch');
    return search === 'true';
  }, []);

  const onHandleChange = useCallback(
    (key: string, value: any, index?: number) => {
      if (!data) return;
      const newEntry: any = {
        ...data,
      };
      if (typeof index !== 'undefined' && index >= 0) {
        newEntry[key][index] = value;
      } else {
        newEntry[key] = value;
        const ref = columns.find((x) => x.refTextKey === key);
        if (ref) {
          if (ref.onSelect) {
            ref.onSelect(newEntry[ref.textKey], undefined, data);
          }
          newEntry[ref.textKey] = undefined;
        }
      }

      setData(newEntry);
    },
    [columns, data]
  );

  const isButtonDisabled = useMemo(() => {
    const requiredColumns = columns.filter((c) => c.required);
    if (requiredColumns.length === 0) {
      return false;
    } else {
      let result = false;
      requiredColumns.forEach((c) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if ((data && data[c.textKey] ? data[c.textKey].toString() : '').length === 0) {
          result = true;
        }
      });
      return result;
    }
  }, [columns, data]);

  useEffect(() => {
    if (entry) {
      setData(entry);
    }
  }, [entry]);

  useEffect(() => {
    const selectionBasedValuesEntries = columns.filter(
      (x) =>
        x.selectionBasedValues &&
        x.selectionBasedValues(data).length === 0 &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data[x.textKey] &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data[x.textKey].length > 0
    );
    if (selectionBasedValuesEntries.length > 0) {
      const newEntry: any = {
        ...data,
      };
      selectionBasedValuesEntries.forEach((x) => {
        newEntry[x.textKey] = undefined;
      });
      setData(newEntry);
    }
  }, [data, columns]);

  return (
    <div>
      {columns.map((column) => {
        const useSelect =
          column.inputType === 'select' && (column.values || column.selectionBasedValues);
        return (
          <Form.Group key={column.textKey} className="mb-3">
            <Form.Label>{column.name}</Form.Label>
            {useSelect && (
              <div>
                {enableSearch && (
                  <FormControl
                    onChange={(e) => {
                      const newSearches = [...searches];
                      const index = newSearches.findIndex((x) => x.textKey === column.textKey);
                      if (index >= 0) {
                        newSearches[index].value = e.target.value;
                      } else {
                        newSearches.push({
                          textKey: column.textKey,
                          value: e.target.value,
                        });
                      }
                      setSearches(newSearches);
                    }}
                    value={searches.find((x) => x.textKey === column.textKey)?.value || ''}
                    placeholder="Suchen"
                    aria-label="search"
                    style={{ marginBottom: '10px' }}
                  />
                )}

                <Form.Select
                  aria-label={column.textKey}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  disabled={column.canEdit === false && entry && data && data[column.textKey]}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  value={data && data[column.textKey] ? data[column.textKey].toString() : ''}
                  onChange={(e) => {
                    onHandleChange(column.textKey, e.target.value);
                    if (column.onSelect) {
                      column.onSelect(
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        data && data[column.textKey] ? data[column.textKey].toString() : undefined,
                        e.target.value,
                        data
                      );
                    }
                  }}
                  isInvalid={
                    column.required &&
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    (data && data[column.textKey] ? data[column.textKey].toString() : '').length ===
                      0
                  }
                >
                  <option>{`${column.name} auswählen`}</option>
                  {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                  {/*@ts-ignore*/}
                  {column.canEdit === false && entry && data && data[column.textKey] && (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <option key={data[column.textKey]} value={data[column.textKey]}>
                      {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                      {/*@ts-ignore*/}
                      {data[column.textKey].toString()}
                    </option>
                  )}
                  {column.values &&
                    !column.selectionBasedValues &&
                    column.values.map((value) => {
                      const columSearch = searches.find((x) => x.textKey === column.textKey);
                      if (columSearch) {
                        const searchValue = columSearch.value;
                        if (searchValue.length == 0) {
                          return (
                            <option key={value.value} value={value.value}>
                              {value.name}
                            </option>
                          );
                        }
                        if (
                          searchValue.length > 0 &&
                          value.name.toLowerCase().includes(searchValue.toLowerCase())
                        ) {
                          return (
                            <option key={value.value} value={value.value}>
                              {value.name}
                            </option>
                          );
                        } else {
                          return null;
                        }
                      }
                      return (
                        <option key={value.value} value={value.value}>
                          {value.name}
                        </option>
                      );
                    })}
                  {column.selectionBasedValues &&
                    column.selectionBasedValues(data).map((value) => (
                      <option key={value.value} value={value.value}>
                        {value.name}
                      </option>
                    ))}
                </Form.Select>
              </div>
            )}
            {!useSelect && column.inputType !== 'boolean' && !column.isArray && (
              <Form.Control
                type={column.inputType || 'string'}
                placeholder={`${column.name} eingeben`}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                value={data && data[column.textKey] ? data[column.textKey].toString() : ''}
                onChange={(e) => onHandleChange(column.textKey, e.target.value)}
                isInvalid={
                  column.required &&
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  (data && data[column.textKey] ? data[column.textKey].toString() : '').length === 0
                }
              />
            )}
            {!useSelect && column.inputType !== 'boolean' && column.isArray && (
              <div style={{ display: 'flex', gap: '10px' }}>
                {data &&
                  data[column.textKey] &&
                  data[column.textKey].map((x, index) => (
                    <div key={index} style={{ display: 'flex', gap: '3px' }}>
                      <Form.Control
                        type={column.inputType || 'string'}
                        value={x.toString()}
                        onFocus={() => setFocusIndex(index)}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={focusIndex === index}
                        placeholder={`${column.name} eingeben`}
                        onChange={(e) => onHandleChange(column.textKey, e.target.value, index)}
                      />
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                          if (!data) return;
                          const newEntry: any = {
                            ...data,
                          };
                          newEntry[column.textKey].splice(index, 1);
                          setData(newEntry);
                        }}
                      >
                        X
                      </Button>
                    </div>
                  ))}
                <Form.Control
                  type={column.inputType || 'string'}
                  placeholder={`${column.name} eingeben`}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  value={''}
                  onChange={(e) =>
                    onHandleChange(
                      column.textKey,
                      e.target.value,
                      data && data[column.textKey] ? data[column.textKey].length : 0
                    )
                  }
                />
              </div>
            )}
            {!useSelect && column.inputType === 'boolean' && (
              <Form.Check
                type="switch"
                id="custom-switch"
                value={data && data[column.textKey] ? data[column.textKey] : false}
                onChange={() => {
                  console.log(
                    ': ',
                    data && data[column.textKey] ? data[column.textKey] : false,
                    !(data && data[column.textKey] ? data[column.textKey] : false)
                  );
                  onHandleChange(
                    column.textKey,
                    !(data && data[column.textKey] ? data[column.textKey] : false)
                  );
                }}
              />
            )}
          </Form.Group>
        );
      })}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          onClick={() => {
            onSave(data);
            setData({});
          }}
          disabled={isButtonDisabled}
        >
          Speichern
        </Button>
        {onDelete && (
          <Button
            variant="secondary"
            onClick={() => {
              onDelete(data);
              setData({});
            }}
          >
            Löschen
          </Button>
        )}
      </div>
    </div>
  );
};

export default DataEdit;
