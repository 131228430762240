import { IDrive } from '@/interfaces/drive.interfaces';
import axios from '@/lib/axios';

export const getDrives = async (): Promise<IDrive[] | null> => {
  try {
    const response = await axios.get('drive');
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const getDriveById = async (id: string): Promise<IDrive | null> => {
  try {
    const response = await axios.get(`drive/${id}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const createDrive = async (data: any): Promise<IDrive | null> => {
  try {
    const response = await axios.post('drive', data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const patchDrive = async (id: string, data: any): Promise<IDrive | null> => {
  try {
    const response = await axios.patch(`drive/${id}`, data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const deleteDrive = async (id: string): Promise<boolean> => {
  try {
    const response = await axios.delete(`drive/${id}`);
    return response.status === 200;
  } catch (error) {
    console.log(error);
  }
  return false;
};
