import { createDrive, getDrives } from '@/api/drive';
import { CreateFromClassDto, IClass } from '@/interfaces/class.interfaces';
import axios from '@/lib/axios';

export const getClasses = async (ignoreDisabled = false): Promise<IClass[] | null> => {
  try {
    const response = await axios.get('class');
    if (response.status === 200) {
      if (!ignoreDisabled) {
        return response.data.filter((x) => !x.disabled);
      }
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const getClassById = async (id: string): Promise<IClass | null> => {
  try {
    const response = await axios.get(`class/${id}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const createClass = async (data: any): Promise<IClass | null> => {
  try {
    const response = await axios.post('class', data);
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const patchClass = async (id: string, data: any): Promise<IClass | null> => {
  try {
    const response = await axios.patch(`class/${id}`, data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const deleteClass = async (id: string): Promise<boolean> => {
  try {
    const response = await axios.delete(`class/${id}`);
    return response.status === 200;
  } catch (error) {
    console.log(error);
  }
  return false;
};

export const createFromClass = async (data: CreateFromClassDto): Promise<boolean> => {
  try {
    const response = await axios.post(`classDriveTransport`, data);
    return response.status === 200;
  } catch (error) {
    console.log(error);
  }
  return false;
};

export const createFinalRound = async (data: CreateFromClassDto): Promise<boolean> => {
  try {
    let drives = await getDrives();
    drives = (drives ? drives : []).filter((x) => x.classId === data.classId);
    drives.sort((a, b) => {
      if (a.distances.length < b.distances.length) {
        return 1;
      }
      if (a.distances.length > b.distances.length) {
        return -1;
      }
      let aSum = 0;
      a.distances.forEach((x) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        aSum += parseFloat(x);
      });
      let bSum = 0;
      b.distances.forEach((x) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        bSum += parseFloat(x);
      });
      if (aSum < bSum) {
        return 1;
      }
      if (aSum > bSum) {
        return -1;
      }
      return 0;
    });

    const createClassDto = {
      name: data.name,
      comment: data.comment,
      count: 100,
    };
    const newClassDrives = drives.slice(0, data.top);

    const newClass = await createClass(createClassDto);
    console.log('newClass: ', newClass);
    if (newClass) {
      const promises: any[] = [];
      newClassDrives.forEach((drive) => {
        promises.push(
          createDrive({
            personId: drive.personId,
            tractorId: drive.tractorId,
            classId: newClass._id.toString(),
            weight: data.keepWeight ? drive.weight : undefined,
            distances: data.keepDistance ? drive.distances : undefined,
            startNumber: drive.startNumber,
            payed: drive.payed,
          })
        );
      });
      await Promise.all(promises);
      return true;
    }
  } catch (error) {
    console.log(error);
  }
  return false;
};
