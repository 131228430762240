import axios from '@/lib/axios';

export const postRegistration = async (data: any): Promise<boolean> => {
  try {
    const response = await axios.post('registration', data);
    if (response.status < 300 && response.status >= 200) {
      return true;
    }
  } catch (error) {
    console.log(error);
  }
  return false;
};

export const patchRegistration = async (data: any): Promise<boolean> => {
  try {
    const response = await axios.patch(`registration/${data.team._id}`, data);
    if (response.status < 300 && response.status >= 200) {
      return true;
    }
  } catch (error) {
    console.log(error);
  }
  return false;
};

export const getRegistrationByTeamId = async (teamId: string): Promise<any> => {
  try {
    const response = await axios.get(`registration/${teamId}`);
    if (response.status < 300 && response.status >= 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const postCheckMail = async (email: string): Promise<boolean> => {
  try {
    const response = await axios.post('registration/mail', { email });
    return response.status === 200;
  } catch (error) {
    console.log(error);
  }
  return false;
};
