import React, { useCallback, useEffect, useMemo, useState } from 'react';

import './person.scss';
import { createPerson, deletePerson, getPersons, patchPerson } from '@/api/person';
import { getTeams } from '@/api/team';
import Crud from '@/components/crud/Crud';
import { IPerson } from '@/interfaces/person.interfaces';
import { ITeam } from '@/interfaces/team.interfaces';

const columns = [
  {
    name: 'Vorname',
    textKey: 'firstName',
    inputType: 'string',
  },
  {
    name: 'Nachname',
    textKey: 'lastName',
    inputType: 'string',
  },
  {
    name: 'Team',
    textKey: 'teamId',
    inputType: 'select',
  },
  {
    name: 'Straße',
    textKey: 'street',
    inputType: 'string',
  },
  {
    name: 'PLZ',
    textKey: 'zipCode',
    inputType: 'string',
  },
  {
    name: 'Stadt',
    textKey: 'city',
    inputType: 'string',
  },
  {
    name: 'Telefon',
    textKey: 'phone',
    inputType: 'tel',
  },
  {
    name: 'E-Mail',
    textKey: 'eMail',
    inputType: 'mail',
  },
];

const Person = () => {
  const [persons, setPersons] = useState<IPerson[] | null>(null);
  const [teams, setTeams] = useState<ITeam[] | null>(null);

  useEffect(() => {
    getPersons().then((data: IPerson[] | null) => {
      if (data) {
        setPersons(data);
      }
    });
    getTeams().then((data: ITeam[] | null) => {
      if (data) {
        setTeams(data);
      }
    });
  }, []);

  const onDelete = useCallback(
    async (entry: IPerson) => {
      if (persons && (await deletePerson(entry._id))) {
        setPersons(persons.filter((c) => c._id !== entry._id));
      }
    },
    [persons]
  );

  const onUpdate = useCallback(async (entry: IPerson) => {
    await patchPerson(entry._id, entry);
  }, []);

  const onCreate = useCallback(async (entry: IPerson) => {
    await createPerson(entry);
  }, []);

  const personColumns = useMemo(() => {
    return columns.map((column) => {
      if (column.textKey === 'teamId') {
        return {
          ...column,
          values: teams?.map((team) => ({
            value: team._id,
            name: team.name,
          })),
        };
      }
      return column;
    });
  }, [teams]);

  return (
    <div className="person">
      {persons && (
        <Crud
          routeName="Person"
          data={persons}
          columns={personColumns}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      )}
    </div>
  );
};

export default Person;
