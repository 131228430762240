/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import './team.scss';
import { getDrives } from '@/api/drive';
import { getPersons } from '@/api/person';
import { createTeam, deleteTeam, getTeams, patchTeam } from '@/api/team';
import Crud from '@/components/crud/Crud';
import { IDrive } from '@/interfaces/drive.interfaces';
import { IPerson } from '@/interfaces/person.interfaces';
import { ITeam } from '@/interfaces/team.interfaces';

const reducer = (accumulator, curr) => accumulator + curr;
const columns = [
  {
    name: 'Name',
    textKey: 'name',
    inputType: 'string',
  },
  {
    name: 'Beschreibung',
    textKey: 'description',
    inputType: 'string',
  },
  {
    name: 'Anzahl Frühstück',
    textKey: 'breakFirstPersonCount',
    inputType: 'number',
  },
  {
    name: 'eMail',
    textKey: 'email',
    inputType: 'string',
  },
  {
    name: 'Kosten',
    textKey: 'costs',
  },
  {
    name: 'Voll bezahlt',
    textKey: 'fullPayed',
    inputType: 'boolean',
  },
];

const Team = () => {
  const [teams, setTeams] = useState<ITeam[] | null>(null);
  const [teamDrives, setTeamDrives] = useState<IDrive[] | null>(null);
  const [persons, setPersons] = useState<IPerson[] | null>(null);

  useEffect(() => {
    getTeams().then((data: ITeam[] | null) => {
      if (data) {
        setTeams(data);
      }
    });
    getDrives().then((data: IDrive[] | null) => {
      if (data) {
        setTeamDrives(data);
      }
    });
    getPersons().then((data: IPerson[] | null) => {
      if (data) {
        setPersons(data);
      }
    });
  }, []);

  const onDelete = useCallback(
    async (entry: ITeam) => {
      if (teams && (await deleteTeam(entry._id))) {
        setTeams(teams.filter((c) => c._id !== entry._id));
      }
    },
    [teams]
  );

  const onUpdate = useCallback(async (entry: ITeam) => {
    await patchTeam(entry._id, entry);
  }, []);

  const onCreate = useCallback(async (entry: ITeam) => {
    await createTeam({
      name: entry.name,
      description: entry.description || undefined,
      breakFirstPersonCount: entry.breakFirstPersonCount || undefined,
      email: entry.email || undefined,
      fullPayed: !!entry.fullPayed,
    });
  }, []);

  const data = useMemo(
    () =>
      teams?.map((team) => {
        const teamPersons = persons?.filter((x) => x.teamId === team._id).map((x) => x._id) || [];
        const drivesLength =
          teamDrives?.filter((x) => x.payed === false && teamPersons.includes(x.personId)).length ||
          0;
        return {
          ...team,
          costs: team.fullPayed ? 0 : 30 * drivesLength + (team.breakFirstPersonCount || 0) * 7.5,
        };
      }),
    [persons, teamDrives, teams]
  );

  return (
    <div className="team">
      <div>
        Frühstück:
        {teams &&
          teams.length > 0 &&
          teams.map((team) => team.breakFirstPersonCount || 0).reduce(reducer)}
      </div>
      {teams && (
        <Crud
          routeName="Team"
          data={data}
          columns={columns}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      )}
    </div>
  );
};

export default Team;
