import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, CloseButton, FormControl, InputGroup, Table } from 'react-bootstrap';

import { getClasses } from '@/api/class';
import { getDrives } from '@/api/drive';
import { getPersons } from '@/api/person';
import { getTeams } from '@/api/team';
import { getTractors } from '@/api/tractor';
import { LineSocketContext } from '@/context/socket';
import useRunningDrives from '@/hooks/runningDrives';
import { IClass } from '@/interfaces/class.interfaces';
import { IDrive } from '@/interfaces/drive.interfaces';
import { IPerson } from '@/interfaces/person.interfaces';
import { ITeam } from '@/interfaces/team.interfaces';
import { ITractor } from '@/interfaces/tractor.interfaces';

export const getLineNumber = () => {
  const href = window.location.href;
  const splitted = href.split('?');
  return splitted.length < 2 ? null : parseInt(splitted[1].replace('line=', ''), 10);
};

const columns = {
  teamName: 'Team',
  personName: 'Fahrer',
  tractorName: 'Trecker',
  startNumber: 'Startnummer',
  distance: 'Strecke',
  canStart: '',
};

export const CurrentDrives = () => {
  const { runningDrives } = useRunningDrives();
  const socket = useContext(LineSocketContext);
  const lineNumber = getLineNumber();
  const [classFilterId, setClassFilterId] = useState<string | null>(null);
  const [persons, setPersons] = useState<IPerson[] | null>(null);
  const [tractors, setTractors] = useState<ITractor[] | null>(null);
  const [drives, setDrives] = useState<IDrive[] | null>(null);
  const [classes, setClasses] = useState<IClass[] | null>(null);
  const [teams, setTeams] = useState<ITeam[] | null>(null);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    getDrives().then((data: IDrive[] | null) => {
      if (data) {
        setDrives(data.filter((x) => x.payed));
      }
    });
  }, [runningDrives]);

  useEffect(() => {
    getPersons().then((data: IPerson[] | null) => {
      if (data) {
        setPersons(data);
      }
    });
    getTractors().then((data: ITractor[] | null) => {
      if (data) {
        setTractors(data);
      }
    });
    getClasses().then((data: IClass[] | null) => {
      if (data) {
        setClasses(data);
      }
    });
    getTeams().then((data: ITeam[] | null) => {
      if (data) {
        setTeams(data);
      }
    });
  }, []);

  const data = useMemo(() => {
    let result = drives ? [...drives] : [];
    result = result?.map((drive) => {
      const person = persons?.find((p) => p._id.toString() === drive.personId.toString());
      return {
        ...drive,
        distance:
          drive.distances && drive.distances.length > 0
            ? drive.distances[drive.distances.length - 1]
            : 0,
        teamName: teams?.find(
          (x) =>
            x._id === persons?.find((p) => p._id.toString() === drive.personId.toString())?.teamId
        )?.name,
        personName: person?.firstName + ' ' + person?.lastName,
        tractorName: tractors?.find((t) => t._id.toString() === drive.tractorId.toString())?.name,
      };
    });
    result.sort((a: any, b: any) => a.distance - b.distance);
    if (classFilterId && result) {
      result = result.filter((x) => x.classId === classFilterId);
    }
    if (search.length > 0 && result) {
      const keys = Object.keys(columns);
      return result.filter((drive) => {
        let included = false;
        keys.forEach((key) => {
          const value = drive[key];
          if (
            !included &&
            typeof value !== 'undefined' &&
            value.toString().toLowerCase().includes(search.toLowerCase())
          ) {
            included = true;
          }
        });
        return included;
      });
    }
    return result;
  }, [classFilterId, drives, persons, search, teams, tractors]);

  if (!lineNumber) {
    return <div>Flascher Link, sorry</div>;
  }
  return (
    <div>
      <div style={{ margin: '1vw 10vw', textAlign: 'center' }}>
        <h1>{`Spur Nummer ${lineNumber}`}</h1>
        <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap', margin: '10px' }}>
          {classes &&
            classes
              .filter((x) => x.enableWeighing)
              .map((c) => (
                <Button
                  key={c._id}
                  onClick={() => setClassFilterId(classFilterId === c._id ? null : c._id)}
                  style={classFilterId === c._id ? { backgroundColor: 'red' } : undefined}
                >
                  {c.name}
                </Button>
              ))}
        </div>
        {classFilterId && (
          <InputGroup className="mb-3">
            <FormControl
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              value={search}
              placeholder="Suchen"
              aria-label="search"
            />
            <Button
              variant="outline-secondary"
              id="button-addon2"
              onClick={() => {
                setSearch('');
              }}
            >
              <CloseButton />
            </Button>
          </InputGroup>
        )}
        {classFilterId && (
          <Table striped bordered hover>
            <thead>
              <tr>
                {Object.keys(columns).map((column) => (
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  <th key={column}>{columns[column]}</th>
                ))}
              </tr>
            </thead>
            <tbody id="">
              {data &&
                data.map((entry: any) => (
                  <tr key={entry._id}>
                    {Object.keys(columns).map((column) => {
                      if (column === 'canStart') {
                        return (
                          <td
                            key={column}
                            style={{
                              width: '100%',
                              textAlign: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {runningDrives.find(
                              (d) => d.line === lineNumber && d.driveId == entry._id
                            ) && (
                              <Button
                                onClick={() => {
                                  socket.emit('updatedDistance', {
                                    driveId: entry._id,
                                    line: lineNumber,
                                  });
                                }}
                              >
                                Abbrechen
                              </Button>
                            )}
                            {!runningDrives.find(
                              (d) => d.line === lineNumber && d.driveId == entry._id
                            ) && (
                              <Button
                                disabled={
                                  runningDrives.filter((d) => d.line === lineNumber).length > 0
                                }
                                onClick={() => {
                                  socket.emit('startDrive', {
                                    driveId: entry._id,
                                    line: lineNumber,
                                  });
                                }}
                              >
                                Start
                              </Button>
                            )}
                          </td>
                        );
                      }
                      return <td key={column}>{entry[column] || 0}</td>;
                    })}
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};
