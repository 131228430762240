import React, { FC, useEffect, useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';

import { getClassStats } from '@/api/stats';
import { IRankingData } from '@/interfaces/stats.interfaces';

import './ranking.scss';

// const classTopMapping = [
//   { name: '2,7t', value: 10 },
//   { name: '3,5t', value: 10 },
//   { name: '4,5t', value: 15 },
//   { name: '6t bis 110', value: 15 },
//   { name: '6t ab 110', value: 10 },
//   { name: '6t Ladys', value: 5 },
//   { name: '8t bis 150', value: 21 },
//   { name: '8t ab 150', value: 15 },
//   { name: '8t Lady', value: 10 },
//   { name: '10t ab 190PS', value: 15 },
//   { name: '10t bis 190PS', value: 15 },
//   { name: '12t', value: 15 },
//   { name: '15t', value: 5 },
// ];

const columns = {
  position: 'Platz',
  personName: 'Fahrer',
  startNumber: 'Startnummer',
  tractorName: 'Trecker',
  teamName: 'Team',
  distances: 'Strecke',
  'distances.length': 'Versuche',
  power: 'Leistung',
};

let interval: any = null;
let classInterval: any = null;

interface IRanking {
  classId?: string;
  filterKey?: string;
}

const Ranking: FC<IRanking> = ({ classId, filterKey }) => {
  const fromQr = window.location.href.includes('qr=true');

  const [data, setData] = useState<IRankingData>();
  const [pageSize, setPageSize] = useState<number>(fromQr ? 9999 : 0);
  const [page, setPage] = useState<number>(fromQr ? 1 : 0);

  useEffect(() => {
    if (fromQr) return;
    if (!classId) {
      const height = window.outerHeight - 97.5;
      setPageSize(Math.floor(height / 41.5));
    } else {
      setPageSize(9999);
    }
  }, [classId, fromQr]);

  useEffect(() => {
    if (classId) {
      getClassStats(classId, filterKey).then((result) => {
        if (result) {
          setData(result);
        }
      });
    } else {
      const queryString = window.location.href;
      const urlParams = queryString.split('classId=');
      console.log('queryString: ', queryString);
      const urlClassId = urlParams[1]?.replace('&qr=true', '');
      if (urlClassId) {
        getClassStats(urlClassId).then((result) => {
          if (result) {
            setData(result);
          }
        });
        classInterval = setInterval(() => {
          getClassStats(urlClassId).then((result) => {
            if (result) {
              setData(result);
            }
          });
        }, 10000);
      }
    }

    return () => {
      if (classInterval) {
        clearInterval(classInterval);
      }
    };
  }, [classId, filterKey]);

  useEffect(() => {
    if (!classId && data && data.drives.length > pageSize) {
      const maxPage = Math.floor(data.drives.length / pageSize);
      if (interval) {
        clearInterval(interval);
      }
      interval = setInterval(() => {
        console.log('page: ', page);
        if (page >= maxPage) {
          setPage(0);
        } else {
          setPage(page + 1);
        }
      }, 10000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [classId, data, page, pageSize]);

  const pageData = useMemo(() => {
    if (data) {
      const d = [...data.drives];
      if (fromQr) return d;
      return d.slice(page * pageSize, page * pageSize + pageSize);
    }
    return [];
  }, [data, fromQr, page, pageSize]);

  if (!data) return null;
  return (
    <div className="ranking">
      <h1>{data.className}</h1>
      {/*{classTopMapping.find((x) => x.name === data.className)?.value && (*/}
      {/*  <div style={{ textAlign: 'center', margin: '10px' }}>{`Die Top ${*/}
      {/*    classTopMapping.find((x) => x.name === data.className)?.value*/}
      {/*  } kommen weiter`}</div>*/}
      {/*)}*/}
      <Table striped bordered hover>
        <thead>
          <tr>
            {Object.keys(columns).map((column) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <th key={column}>{columns[column]}</th>
            ))}
          </tr>
        </thead>
        <tbody id="">
          {pageData.map((entry: any) => (
            <tr key={entry._id}>
              {Object.keys(columns).map((column) => {
                let value = entry[column] || 0;
                if (column === 'distances') {
                  value = value.map((x) => parseFloat(x)).reduce((pv, cv) => pv + cv, 0);
                } else if (column === 'distances.length' && entry['distances']) {
                  value = entry['distances'].length;
                }
                return <td key={column}>{value}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Ranking;
