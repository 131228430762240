const collator = new Intl.Collator('de', { numeric: true, sensitivity: 'base' });

export const sortRoomDescAlphaNumeric = (a: any, b: any, sortField: keyof any, sortAsc = true) => {
  if (sortAsc) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return collator.compare(a[sortField], b[sortField]);
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return collator.compare(b[sortField], a[sortField]);
  }
};
