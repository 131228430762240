import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';

import { getClasses } from '@/api/class';
import { getClassStats } from '@/api/stats';
import { IClass } from '@/interfaces/class.interfaces';
import { IRankingData } from '@/interfaces/stats.interfaces';

const classTopMapping = [
  { name: '2,7t', value: 10 },
  { name: '3,5t', value: 10 },
  { name: '4,5t', value: 15 },
  { name: '6t bis 110', value: 15 },
  { name: '6t ab 110', value: 10 },
  { name: '6t Ladys', value: 5 },
  { name: '8t bis 150', value: 21 },
  { name: '8t ab 150', value: 15 },
  { name: '8t Lady', value: 10 },
  { name: '10t ab 190PS', value: 15 },
  { name: '10t bis 190PS', value: 15 },
  { name: '12t', value: 15 },
  { name: '15t', value: 5 },
];

const columns = {
  position: 'Platz',
  personName: 'Fahrer',
  startNumber: 'Startnummer',
  tractorName: 'Trecker',
  teamName: 'Team',
  distances: 'Strecke',
  'distances.length': 'Versuche',
};

let interval: any = null;
const ms = 15 * 1000;

const KioskMode = () => {
  const [data, setData] = useState<IRankingData>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [classIndex, setClassIndex] = useState<number>(0);
  const [classes, setClasses] = useState<IClass[]>([]);
  const [pageSize, setPageSize] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [fetchNext, setFetchNext] = useState<boolean>(false);

  const classId = useMemo(() => {
    if (classIndex >= 0 && classes.length > 0) {
      return classes[classIndex]._id;
    }
    return undefined;
  }, [classIndex, classes]);

  useEffect(() => {
    getClasses().then((result: IClass[] | null) => {
      if (result) {
        setClasses([...result]);
        getClassStats([...result][0]._id).then((result) => {
          if (result) {
            setData(result);
            const height = window.outerHeight - 97.5;
            const newPageSize = Math.floor(height / 41.5);
            const maxPage = Math.floor(result.drives.length / newPageSize);
            if (maxPage === 0) {
              setTimeout(() => {
                setFetchNext(true);
              }, ms);
            }
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    if (classId) {
      const height = window.outerHeight - 97.5;
      setPageSize(Math.floor(height / 41.5));
    } else {
      setPageSize(9999);
    }
  }, [classId]);

  useEffect(() => {
    if (classes.length > 0 && fetchNext) {
      const newClassIndex = classes.length - 1 > classIndex ? classIndex + 1 : 0;
      setClassIndex(newClassIndex);
      getClassStats(classes[newClassIndex]._id).then((result) => {
        if (result) {
          setData(result);
          const height = window.outerHeight - 97.5;
          const newPageSize = Math.floor(height / 41.5);
          const maxPage = Math.floor(result.drives.length / newPageSize);
          if (maxPage === 0) {
            setTimeout(() => {
              setFetchNext(true);
            }, ms);
          }
        }
      });
      setFetchNext(false);
    }
  }, [classIndex, classes, fetchNext]);

  useEffect(() => {
    if (classId && data && data.drives.length > pageSize) {
      const maxPage = Math.floor(data.drives.length / pageSize);
      if (interval) {
        clearInterval(interval);
      }
      interval = setInterval(() => {
        console.log('page: ', page);
        if (page >= maxPage) {
          setFetchNext(true);
          setTimeout(() => {
            setPage(0);
          }, 100);
        } else {
          setPage(page + 1);
        }
      }, ms);
    }
    return () => {
      clearInterval(interval);
    };
  }, [classId, data, page, pageSize]);

  const pageData = useMemo(() => {
    if (data) {
      const d = [...data.drives];
      return d.slice(page * pageSize, page * pageSize + pageSize);
    }
    return [];
  }, [data, page, pageSize]);

  return (
    <div style={{ margin: '0 10vw', textAlign: 'center' }}>
      <h1>{classes.length > 0 ? classes[classIndex].name : ''}</h1>
      <div style={{ textAlign: 'center', margin: '10px' }}>{`Die Top ${
        classTopMapping.find((x) => x.name === (classes.length > 0 ? classes[classIndex].name : ''))
          ?.value
      } kommen weiter`}</div>
      <Table striped bordered hover>
        <thead>
          <tr>
            {Object.keys(columns).map((column) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <th key={column}>{columns[column]}</th>
            ))}
          </tr>
        </thead>
        <tbody id="">
          {pageData.map((entry: any) => (
            <tr key={entry._id}>
              {Object.keys(columns).map((column) => {
                let value = entry[column] || 0;
                if (column === 'distances') {
                  value = value.map((x) => parseFloat(x)).reduce((pv, cv) => pv + cv, 0);
                } else if (column === 'distances.length' && entry['distances']) {
                  value = entry['distances'].length;
                }
                return <td key={column}>{value}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default KioskMode;
