import { atom, useRecoilState } from 'recoil';

export interface IRunningDrive {
  line: number;
  driveId: string;
}

const state = atom<IRunningDrive[]>({
  key: 'runningDrives',
  default: [],
});

const useRunningDrives = () => {
  const [runningDrives, setData] = useRecoilState(state);

  const setRunningDrives = (data: IRunningDrive[]) => {
    setData(data);
  };

  return {
    runningDrives,
    setRunningDrives,
  };
};

export default useRunningDrives;
