import { FC, useContext, useEffect } from 'react';

import { SocketContext } from '@/context/socket';
import useClassStartNumbers, { IClassStartNumberInterface } from '@/hooks/classStartNumbers';

type SocketProviderProps = {
  children: JSX.Element;
};

const SocketProvider: FC<SocketProviderProps> = ({ children }) => {
  const socket = useContext(SocketContext);
  const { setClassStartNumbers } = useClassStartNumbers();

  useEffect(() => {
    socket.on('connect', () => {
      console.log('connected');
    });
    socket.on('disconnect', () => {
      console.log('disconnect');
    });
    socket.on('initStartNumbers', (data: IClassStartNumberInterface[]) => {
      if (data) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setClassStartNumbers(data);
      }
    });
    socket.on('connect_error', (err) => {
      console.log('connect_error', err);
    });
    return () => {
      socket.off('connect', () => {
        console.debug('connect (cleanup)');
      });
      socket.off('disconnect', () => {
        console.debug('Websocket disconnected (cleanup)');
      });
      socket.off('initStartNumbers', () => {
        console.debug('Websocket initStartNumbers (cleanup)');
      });
      socket.off('connect_error', () => {
        console.debug('connect_error (cleanup)');
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>{children}</div>;
};

export default SocketProvider;
