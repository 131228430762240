import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { getClasses } from '@/api/class';
import { getDrives } from '@/api/drive';
import { IClass } from '@/interfaces/class.interfaces';
import { IDrive } from '@/interfaces/drive.interfaces';

const RankingOverview = () => {
  const [classes, setClasses] = useState<IClass[]>([]);
  const [drives, setDrives] = useState<IDrive[]>([]);
  const fromQr = window.location.href.includes('qr=true');

  useEffect(() => {
    if (fromQr) {
      document.location.replace(window.location.href);
    }
  }, [fromQr]);

  useEffect(() => {
    getClasses().then((data) => {
      if (data) {
        setClasses(data);
      }
    });
    getDrives().then((data) => {
      if (data) {
        setDrives(data);
      }
    });
  }, []);

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Ranking</h1>
      <div className="home">
        {classes.map((classe) => (
          <Link
            key={classe._id}
            to={`/stats?classId=${classe._id}${fromQr ? '&qr=true' : ''}`}
            replace
          >
            <Card key={classe._id} style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title>{classe.name}</Card.Title>
                <Card.Text>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${classe.comment}`,
                    }}
                  />
                </Card.Text>
                <Card.Text>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${drives.filter((x) => x.classId === classe._id).length}`,
                    }}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default RankingOverview;
