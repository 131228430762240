import React from 'react';
import { Modal, ThemeProvider } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import 'bootstrap/dist/css/bootstrap.min.css';

import { socket, SocketContext } from '@/context/socket';
import SocketProvider from '@/providers/SocketProvider';
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage';

import App from './App';
import reportWebVitals from './reportWebVitals';

const currentDate = new Date();
const checkDate = new Date(currentDate.getFullYear(), 7, 1);
const checkEndDate = new Date(currentDate.getFullYear(), 8, 13, 10);
const isTesting = window.location.href.includes('test=true');

const admin = window.location.href.includes('admin=true');

if (admin) {
  setLocalStorage('admin', 'true');
  window.location.href = window.location.href.replace('?admin=true', '');
}
console.log(
  'currentDate.getTime() >= checkEndDate.getTime(): ',
  currentDate.getTime() >= checkEndDate.getTime()
);
if (
  currentDate.getTime() >= checkEndDate.getTime() &&
  getLocalStorage('admin') !== 'true' &&
  !window.location.href.includes('qr=true')
) {
  ReactDOM.render(
    <div
      style={{
        display: 'block',
        position: 'fixed',
        backgroundColor: '#6363639c',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 99,
      }}
    >
      <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>Anmeldung ist vorbei.</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Anmeldungen nur noch vor Ort auf dem Festzelt</p>
        </Modal.Body>
      </Modal.Dialog>
    </div>,
    document.getElementById('root')
  );
} else if (
  currentDate.getTime() < checkDate.getTime() &&
  getLocalStorage('admin') !== 'true' &&
  getLocalStorage('reg') !== 'true' &&
  !isTesting
) {
  ReactDOM.render(
    <div
      style={{
        display: 'block',
        position: 'fixed',
        backgroundColor: '#6363639c',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 99,
      }}
    >
      <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>Anmeldung startet am 01.08.2024</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Bald geht es los. Du musst Dich für die Anmeldung noch etwas gedulden.</p>
        </Modal.Body>
      </Modal.Dialog>
    </div>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
        <SocketContext.Provider value={socket}>
          <HashRouter>
            <RecoilRoot>
              <SocketProvider>
                <App />
              </SocketProvider>
            </RecoilRoot>
          </HashRouter>
        </SocketContext.Provider>
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
