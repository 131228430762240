import { atom, useRecoilState } from 'recoil';

export interface IStartNumbersEntry {
  number: number;
  socketId: string | null;
}
export interface IClassStartNumberInterface {
  classId: string;
  startNumbers: IStartNumbersEntry[];
}

const state = atom<IClassStartNumberInterface[]>({
  key: 'classStartNumbers',
  default: [],
});

const useClassStartNumbers = () => {
  const [classStartNumbers, setData] = useRecoilState(state);

  const setClassStartNumbers = (data: IClassStartNumberInterface[]) => {
    setData(data);
  };

  return {
    classStartNumbers,
    setClassStartNumbers,
  };
};

export default useClassStartNumbers;
