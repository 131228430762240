import React, { useCallback, useEffect, useMemo, useState } from 'react';

import './tractor.scss';
import { getTeams } from '@/api/team';
import { createTractor, deleteTractor, getTractors, patchTractor } from '@/api/tractor';
import Crud from '@/components/crud/Crud';
import { ITeam } from '@/interfaces/team.interfaces';
import { ITractor } from '@/interfaces/tractor.interfaces';

const columns = [
  {
    name: 'Name',
    textKey: 'name',
    inputType: 'string',
    required: true,
  },
  {
    name: 'Baujahr',
    textKey: 'year',
    inputType: 'number',
  },
  {
    name: 'PS',
    textKey: 'power',
    inputType: 'number',
    required: true,
  },
  {
    name: 'Code',
    textKey: 'code',
    inputType: 'string',
  },
  {
    name: 'Team',
    textKey: 'teamId',
    inputType: 'select',
    required: true,
  },
];

const Tractor = () => {
  const [tractors, setTractors] = useState<ITractor[] | null>(null);
  const [teams, setTeams] = useState<ITeam[] | null>(null);

  useEffect(() => {
    getTractors().then((data: ITractor[] | null) => {
      if (data) {
        setTractors(data);
      }
    });
    getTeams().then((data: ITeam[] | null) => {
      if (data) {
        setTeams(data);
      }
    });
  }, []);

  const onDelete = useCallback(
    async (entry: ITractor) => {
      if (tractors && (await deleteTractor(entry._id))) {
        setTractors(tractors.filter((c) => c._id !== entry._id));
      }
    },
    [tractors]
  );

  const onUpdate = useCallback(async (entry: ITractor) => {
    await patchTractor(entry._id, entry);
  }, []);

  const onCreate = useCallback(async (entry: ITractor) => {
    await createTractor({
      name: entry.name,
      year: entry.year || undefined,
      power: entry.power,
      code: entry.code || undefined,
      teamId: entry.teamId,
    });
  }, []);

  const tractorColumns = useMemo(() => {
    return columns.map((column) => {
      if (column.textKey === 'teamId') {
        return {
          ...column,
          values: teams?.map((team) => ({
            value: team._id,
            name: team.name,
          })),
        };
      }
      return column;
    });
  }, [teams]);

  return (
    <div className="tractor">
      {tractors && (
        <Crud
          routeName="Tractor"
          data={tractors}
          columns={tractorColumns}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      )}
    </div>
  );
};

export default Tractor;
