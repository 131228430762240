import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import './drive.scss';
import { Button } from 'react-bootstrap';

import { getClasses } from '@/api/class';
import { createDrive, deleteDrive, getDrives, patchDrive } from '@/api/drive';
import { getPersons } from '@/api/person';
import { getTeams } from '@/api/team';
import { getTractors } from '@/api/tractor';
import Crud from '@/components/crud/Crud';
import { getStartNumbers } from '@/components/registration/Registration';
import { SocketContext } from '@/context/socket';
import useClassStartNumbers from '@/hooks/classStartNumbers';
import { IClass } from '@/interfaces/class.interfaces';
import { IDrive } from '@/interfaces/drive.interfaces';
import { IPerson } from '@/interfaces/person.interfaces';
import { ITeam } from '@/interfaces/team.interfaces';
import { ITractor } from '@/interfaces/tractor.interfaces';

const columns = [
  {
    name: 'Klasse',
    textKey: 'classId',
    inputType: 'select',
    required: true,
  },
  {
    name: 'Team',
    textKey: 'teamId',
    inputType: 'select',
    required: true,
  },
  {
    name: 'Person',
    textKey: 'personId',
    inputType: 'select',
    required: true,
    refTextKey: 'teamId',
  },
  {
    name: 'Trecker',
    textKey: 'tractorId',
    inputType: 'select',
    required: true,
    refTextKey: 'teamId',
  },
  {
    name: 'PS',
    textKey: 'power',
  },
  {
    name: 'Gewicht',
    textKey: 'weight',
    inputType: 'number',
  },
  {
    name: 'Startnummer',
    textKey: 'startNumber',
    inputType: 'select',
    refTextKey: 'classId',
    required: true,
    canEdit: false,
  },
  {
    name: 'Versuche',
    textKey: 'distances.length',
  },
  {
    name: 'Strecken',
    textKey: 'distances',
    inputType: 'number',
    isArray: true,
  },
  {
    name: 'Bezahlt',
    textKey: 'payed',
    inputType: 'boolean',
  },
];

const Drive = () => {
  const { classStartNumbers } = useClassStartNumbers();
  const socket = useContext(SocketContext);
  const socketId = socket.id;
  console.log('socketId: ', socketId);
  console.log('classStartNumbers: ', classStartNumbers);
  const [persons, setPersons] = useState<IPerson[] | null>(null);
  const [tractors, setTractors] = useState<ITractor[] | null>(null);
  const [drives, setDrives] = useState<IDrive[] | null>(null);
  const [classes, setClasses] = useState<IClass[] | null>(null);
  const [teams, setTeams] = useState<ITeam[] | null>(null);
  const [classFilterId, setClassFilterId] = useState<string | null>(null);

  useEffect(() => {
    getPersons().then((data: IPerson[] | null) => {
      if (data) {
        setPersons(data);
      }
    });
    getDrives().then((data: IDrive[] | null) => {
      if (data) {
        setDrives(data);
      }
    });
    getTractors().then((data: ITractor[] | null) => {
      if (data) {
        setTractors(data);
      }
    });
    getClasses().then((data: IClass[] | null) => {
      if (data) {
        setClasses(data);
      }
    });
    getTeams().then((data: ITeam[] | null) => {
      if (data) {
        setTeams(data);
      }
    });
  }, []);

  const onDelete = useCallback(
    async (entry: IDrive) => {
      if (drives && (await deleteDrive(entry._id))) {
        setDrives(drives.filter((c) => c._id !== entry._id));
      }
    },
    [drives]
  );

  const onUpdate = useCallback(async (entry: IDrive) => {
    await patchDrive(entry._id, {
      personId: entry.personId,
      tractorId: entry.tractorId,
      classId: entry.classId,
      teamId: entry.teamId,
      weight: entry.weight,
      startNumber: entry.startNumber,
      distances: entry.distances,
      payed: entry.payed,
    });
  }, []);

  const onCreate = useCallback(async (entry: IDrive) => {
    await createDrive({
      personId: entry.personId,
      tractorId: entry.tractorId,
      classId: entry.classId,
      weight: entry.weight || undefined,
      startNumber: entry.startNumber || undefined,
      distances: entry.distances || [],
    });
  }, []);

  const driveColumns = useMemo(() => {
    return columns.map((column) => {
      if (column.textKey === 'classId') {
        return {
          ...column,
          values: classes?.map((classe) => ({
            value: classe._id,
            name: classe.name,
          })),
        };
      }
      if (column.textKey === 'teamId') {
        return {
          ...column,
          values: teams?.map((team) => ({
            value: team._id,
            name: team.name,
          })),
        };
      }
      if (column.textKey === 'personId') {
        return {
          ...column,
          values: persons?.map((person) => ({
            value: person._id,
            name: person.firstName + ' ' + person.lastName,
          })),
          selectionBasedValues: function (data: any) {
            if (persons) {
              return persons
                .filter((p) => p.teamId.toString() === data.teamId)
                .map((person) => ({
                  value: person._id,
                  name: person.firstName + ' ' + person.lastName,
                }));
            }
            return [];
          },
        };
      }
      if (column.textKey === 'tractorId') {
        return {
          ...column,
          values: tractors?.map((tractor) => ({
            value: tractor._id,
            name: tractor.name,
          })),
          selectionBasedValues: (data: any) => {
            if (tractors) {
              return tractors
                .filter((p) => p.teamId.toString() === data.teamId)
                .map((tractor) => ({
                  value: tractor._id,
                  name: tractor.name,
                }));
            }
            return [];
          },
        };
      }
      if (column.textKey === 'startNumber') {
        return {
          ...column,
          selectionBasedValues: (data: any) => {
            const classStartNumberEntry = classStartNumbers.find((x) => x.classId === data.classId);
            const classe = classes?.find((x) => x._id === data.classId);
            if (classStartNumberEntry && classe) {
              const numbers = getStartNumbers(classe.count);
              if (classStartNumberEntry.startNumbers.length > 0) {
                return numbers
                  .filter(
                    (x) =>
                      !classStartNumberEntry.startNumbers.find(
                        (entry) => entry.number === x && entry.socketId !== socketId
                      ) && !drives?.find((y) => y.classId === data.classId && y.startNumber === x)
                  )
                  .map((x) => ({ name: x.toString(), value: x.toString() }));
              }
              return numbers
                .filter(
                  (x) => !drives?.find((y) => y.classId === data.classId && y.startNumber === x)
                )
                .map((x) => ({ name: x.toString(), value: x.toString() }));
            }
            return [];
          },
          onSelect: (oldValue: string | undefined, newValue: string | undefined, data: any) => {
            if (oldValue && !newValue) {
              socket.emit('deleteClassStartNumber', {
                startNumber: oldValue,
                classId: data.classId,
              });
            } else if (oldValue) {
              socket.emit('changeClassStartNumber', {
                startNumber: newValue,
                classId: data.classId,
                oldStartNumber: oldValue,
                oldClassId: data.classId,
              });
            } else if (newValue) {
              socket.emit('useClassStartNumber', {
                startNumber: newValue,
                classId: data.classId,
              });
            }
          },
        };
      }
      return column;
    });
  }, [classStartNumbers, classes, drives, persons, socket, socketId, teams, tractors]);

  const data = useMemo(() => {
    const result = drives?.map((drive) => ({
      ...drive,
      teamId: teams?.find(
        (x) =>
          x._id === persons?.find((p) => p._id.toString() === drive.personId.toString())?.teamId
      )?._id,
      'distances.length': drive.distances?.length || 0,
      power: tractors?.find((x) => x._id === drive.tractorId)?.power || 0,
    }));
    if (classFilterId && result) {
      return result.filter((x) => x.classId === classFilterId);
    }
    return result;
  }, [classFilterId, drives, persons, teams, tractors]);

  return (
    <div className="drive">
      {drives && (
        <Crud
          routeName="Drive"
          data={data}
          columns={driveColumns}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onDelete={onDelete}
          filterNode={
            classes ? (
              <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap', margin: '10px' }}>
                {classes.map((c) => (
                  <Button
                    key={c._id}
                    onClick={() => setClassFilterId(classFilterId === c._id ? null : c._id)}
                    style={classFilterId === c._id ? { backgroundColor: 'red' } : undefined}
                  >
                    {c.name}
                  </Button>
                ))}
              </div>
            ) : undefined
          }
        />
      )}
    </div>
  );
};

export default Drive;
