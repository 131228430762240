import { ITractor } from '@/interfaces/tractor.interfaces';
import axios from '@/lib/axios';

export const getTractors = async (): Promise<ITractor[] | null> => {
  try {
    const response = await axios.get('tractor');
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const getTractorById = async (id: string): Promise<ITractor | null> => {
  try {
    const response = await axios.get(`tractor/${id}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const createTractor = async (data: any): Promise<ITractor | null> => {
  try {
    const response = await axios.post('tractor', data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const patchTractor = async (id: string, data: any): Promise<ITractor | null> => {
  try {
    const response = await axios.patch(`tractor/${id}`, data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const deleteTractor = async (id: string): Promise<boolean> => {
  try {
    const response = await axios.delete(`tractor/${id}`);
    return response.status === 200;
  } catch (error) {
    console.log(error);
  }
  return false;
};
