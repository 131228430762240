/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,@typescript-eslint/no-unused-vars */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Accordion,
  Button,
  FloatingLabel,
  Form,
  Modal,
  Overlay,
  ProgressBar,
  Spinner,
  Tooltip,
} from 'react-bootstrap';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { getClasses } from '@/api/class';
import { getPersons } from '@/api/person';
import {
  getRegistrationByTeamId,
  patchRegistration,
  postCheckMail,
  postRegistration,
} from '@/api/registration';
import { getTeamById, getTeams } from '@/api/team';
import DataEdit, { IEditColumn } from '@/components/DataEdit/DataEdit';
import { SocketContext } from '@/context/socket';
import useClassStartNumbers from '@/hooks/classStartNumbers';
import { getLocalStorage, setLocalStorage, removeLocalStorageKey } from '@/utils/localStorage';
import { emailValidation } from '@/utils/validation';
import './registration.scss';

const copyToClipboard = (value: string) => {
  if (!navigator.clipboard) {
    return;
  }
  navigator.clipboard.writeText(value);
};

export const getStartNumbers = (highEnd: number) => {
  const list: number[] = [];
  for (let i = 1; i <= highEnd; i++) {
    list.push(i);
  }
  return list;
};

const personColumns: IEditColumn[] = [
  {
    name: 'Vorname',
    textKey: 'firstName',
    inputType: 'string',
    required: true,
  },
  {
    name: 'Nachname',
    textKey: 'lastName',
    inputType: 'string',
    required: true,
  },
  {
    name: 'Straße',
    textKey: 'street',
    inputType: 'string',
  },
  {
    name: 'PLZ',
    textKey: 'zipCode',
    inputType: 'string',
  },
  {
    name: 'Stadt',
    textKey: 'city',
    inputType: 'string',
  },
  {
    name: 'Telefon',
    textKey: 'phone',
    inputType: 'tel',
  },
  {
    name: 'E-Mail',
    textKey: 'eMail',
    inputType: 'mail',
  },
  {
    name: 'Über die Person (Lebensmotto, Leidenschaft, etc.)',
    textKey: 'description',
    inputType: 'string',
    required: true,
  },
];
const tractorColumns: IEditColumn[] = [
  {
    name: 'Fabrikat',
    textKey: 'name',
    inputType: 'string',
    required: true,
  },
  {
    name: 'Baujahr',
    textKey: 'year',
    inputType: 'number',
  },
  {
    name: 'PS',
    textKey: 'power',
    inputType: 'number',
    required: true,
  },
  {
    name: 'Code',
    textKey: 'code',
    inputType: 'string',
  },
];

const Registration = () => {
  const { classStartNumbers } = useClassStartNumbers();
  const socket = useContext(SocketContext);
  const socketId = socket.id;

  const [showRegistrationButton, setShowRegistrationButton] = useState(false);
  const [showCopy, setShowCopy] = useState(false);
  const [showEmailUsed, setShowEmailUsed] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const [team, setTeam] = useState<any>([]);
  const [persons, setPersons] = useState<any[]>([]);
  const [tractors, setTractors] = useState<any[]>([]);
  const [drives, setDrives] = useState<any[]>([]);
  const [classes, setClasses] = useState<any[]>([]);

  const target = useRef(null);

  const year = useMemo(() => {
    return new Date().getFullYear();
  }, []);
  const showDrives = useMemo(
    () => persons.length > 0 && tractors.length > 0,
    [persons.length, tractors.length]
  );
  const driveColumns: IEditColumn[] = useMemo(
    () => [
      {
        name: 'Person',
        textKey: 'personId',
        inputType: 'select',
        required: true,
        values: persons.map((x, index) => ({
          name: x.firstName + ' ' + x.lastName,
          value: x._id || index,
        })),
      },
      {
        name: 'Traktor',
        textKey: 'tractorId',
        inputType: 'select',
        required: true,
        values: tractors.map((x, index) => ({ name: x.name, value: x._id || index })),
      },
      {
        name: 'Klasse',
        textKey: 'classId',
        inputType: 'select',
        required: true,
        values: classes.map((x) => ({ name: x.name, value: x._id })),
      },
      {
        name: 'Startnummer',
        textKey: 'startNumber',
        inputType: 'select',
        required: true,
        refTextKey: 'classId',
        canEdit: false,
        selectionBasedValues: (data) => {
          const classStartNumberEntry = classStartNumbers.find((x) => x.classId === data.classId);
          const classe = classes.find((x) => x._id === data.classId);
          if (classStartNumberEntry && classe) {
            const numbers = getStartNumbers(classe.count);
            if (classStartNumberEntry.startNumbers.length > 0) {
              return numbers
                .filter(
                  (x) =>
                    !classStartNumberEntry.startNumbers.find(
                      (entry) => entry.number === x && entry.socketId !== socketId
                    ) &&
                    !drives.find(
                      (y) => y.classId === data.classId && parseInt(y.startNumber, 10) === x
                    )
                )
                .map((x) => ({ name: x.toString(), value: x.toString() }));
            }
            return numbers
              .filter(
                (x) =>
                  !drives.find(
                    (y) => y.classId === data.classId && parseInt(y.startNumber, 10) === x
                  )
              )
              .map((x) => ({ name: x.toString(), value: x.toString() }));
          }
          return [];
        },
        onSelect: (oldValue, newValue, data) => {
          if (oldValue && !newValue) {
            socket.emit('deleteClassStartNumber', {
              startNumber: oldValue,
              classId: data.classId,
            });
          } else if (oldValue) {
            socket.emit('changeClassStartNumber', {
              startNumber: newValue,
              classId: data.classId,
              oldStartNumber: oldValue,
              oldClassId: data.classId,
            });
          } else if (newValue) {
            socket.emit('useClassStartNumber', {
              startNumber: newValue,
              classId: data.classId,
            });
          }
        },
      },
    ],
    [classStartNumbers, classes, drives, persons, socket, socketId, tractors]
  );
  const progressNow = useMemo(() => {
    let now = 0;
    if (team.name && team.name.length > 0) {
      now += 15;
    }
    if (team.email && emailValidation(team.email)) {
      now += 10;
    }
    if (persons.length > 0) {
      now += 25;
    }
    if (tractors.length > 0) {
      now += 25;
    }
    if (drives.length > 0) {
      now += 25;
    }
    return now;
  }, [drives.length, persons.length, team.email, team.name, tractors.length]);

  const handleSavePerson = useCallback(
    (data: object, index?: number) => {
      const newPersons = [...persons];
      if (typeof index !== 'undefined' && index >= 0) {
        newPersons[index] = data;
      } else {
        newPersons.push(data);
      }
      setPersons(newPersons);
    },
    [persons]
  );
  const handleDeletePerson = useCallback(
    (deleteIndex) => {
      const newPersons = [...persons.filter((x, index) => index !== deleteIndex)];
      const newDrives = [...drives.filter((x) => parseInt(x.personId, 10) !== deleteIndex)];
      setPersons(newPersons);
      setDrives(newDrives);
    },
    [drives, persons]
  );
  const handleSaveTractors = useCallback(
    (data: object, index?: number) => {
      const newTractors = [...tractors];
      if (typeof index !== 'undefined' && index >= 0) {
        newTractors[index] = data;
      } else {
        newTractors.push(data);
      }
      setTractors(newTractors);
    },
    [tractors]
  );
  const handleDeleteTractors = useCallback(
    (deleteIndex) => {
      const newTractors = [...tractors.filter((x, index) => index !== deleteIndex)];
      const newDrives = [...drives.filter((x) => parseInt(x.tractorId, 10) !== deleteIndex)];
      setTractors(newTractors);
      setDrives(newDrives);
    },
    [drives, tractors]
  );
  const handleSaveDrive = useCallback(
    (data: any, index?: number) => {
      const newDrive = [...drives];
      console.log('data: ', data);
      if (typeof index !== 'undefined' && index >= 0) {
        newDrive[index] = data;
      } else {
        newDrive.push(data);
      }
      setDrives(newDrive);
    },
    [drives]
  );
  const handleDeleteDrive = useCallback(
    (deleteIndex) => {
      const entry = drives.find((x, index) => index === deleteIndex);
      const newDrives = [...drives.filter((x, index) => index !== deleteIndex)];
      if (entry) {
        socket.emit('deleteClassStartNumber', {
          startNumber: entry.startNumber,
          classId: entry.classId,
        });
      }
      setDrives(newDrives);
    },
    [socket, drives]
  );
  const handleTeamEntry = useCallback(
    (key: string, value: string) => {
      const newTeam = { ...team };
      newTeam[key] = value;
      setTeam(newTeam);
    },
    [team]
  );
  const handleSendRegistration = useCallback(async () => {
    const isUpdate = !!team._id;
    console.log('isUpdate: ', isUpdate);
    if (!isUpdate) {
      const useableEmail = await postCheckMail(team.email);
      if (!useableEmail) {
        setShowEmailUsed(true);
        return;
      }
    }
    const data = {
      team,
      persons,
      tractors,
      drives,
    };
    setIsLoadingRegister(true);
    let result = false;
    if (isUpdate) {
      result = await patchRegistration(data);
    } else {
      result = await postRegistration(data);
    }
    setIsLoadingRegister(false);
    if (result) {
      removeLocalStorageKey('persons');
      removeLocalStorageKey('tractors');
      removeLocalStorageKey('drives');
      removeLocalStorageKey('team');
      setShowSuccess(true);
    }
  }, [drives, persons, team, tractors]);

  useEffect(() => {
    getClasses().then((data) => {
      if (data) {
        setClasses(data);
      }
      setLoading(false);
    });
    const handleStorage = () => {
      const storedPersons = getLocalStorage('persons');
      const storedTractors = getLocalStorage('tractors');
      const storedTeam = getLocalStorage('team');
      if (storedPersons) setPersons(JSON.parse(storedPersons));
      if (storedTractors) setTractors(JSON.parse(storedTractors));
      if (storedTeam) setTeam(JSON.parse(storedTeam));
    };
    const params = new URLSearchParams(window.location.search);
    const email = params.get('eMail');
    const code = params.get('code');
    if (email && code) {
      getTeamById(code).then((team) => {
        if (team && team.email === email) {
          setTeam(team);
          getRegistrationByTeamId(code).then((data) => {
            console.log(data);
            if (data && data.persons && data.tractors && data.drives) {
              setPersons(data.persons);
              setTractors(data.tractors);
              setDrives(data.drives);
            } else {
              handleStorage();
            }
          });
        } else {
          handleStorage();
        }
      });
    } else {
      handleStorage();
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setLocalStorage('persons', JSON.stringify(persons));
      setLocalStorage('tractors', JSON.stringify(tractors));
      setLocalStorage('team', JSON.stringify(team));
    }
  }, [team, persons, tractors, isLoading]);

  useEffect(() => {
    if (progressNow === 100) {
      setShowRegistrationButton(true);
    } else {
      setShowRegistrationButton(false);
    }
  }, [progressNow]);

  return (
    <div className="registration">
      <h1>{`Grafschafter Trecker Treck ${year}${
        team && team._id ? ' - Anmeldung anpassen' : ''
      }`}</h1>
      <SwitchTransition>
        <CSSTransition
          key={isLoadingRegister ? 'spinner' : 'thanks'}
          addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
          classNames="fade"
        >
          <>
            {isLoadingRegister && (
              <div className="registration-spinner">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
            {showSuccess && (
              <div className="registration-spinner">
                <h1>
                  {team && team._id ? 'Danke für Deine Änderungen' : 'Danke für Deine Anmeldung'}
                </h1>
                <p>
                  {team && team._id
                    ? 'Du kannst deine Änderungen jederzeit hier einsehen.'
                    : 'Du erhältst eine Bestätigungsmail.'}
                </p>
              </div>
            )}
          </>
        </CSSTransition>
      </SwitchTransition>
      <div className="progressBar">
        <SwitchTransition>
          <CSSTransition
            key={showRegistrationButton ? 'Button' : 'ProgressBar'}
            addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
            classNames="fade"
          >
            <>
              {!showRegistrationButton && (
                <ProgressBar
                  now={progressNow}
                  variant={progressNow === 100 ? 'success' : undefined}
                />
              )}
              {showRegistrationButton && (
                <div>
                  <Button onClick={handleSendRegistration}>Anmeldung abschließen</Button>
                </div>
              )}
            </>
          </CSSTransition>
        </SwitchTransition>
      </div>
      <p>
        Zur Anmeldung fülle bitte diese Formular aus. Bei Änderungen bitte über den Link in der Mail
        oder wende Dich an:
        <a
          ref={target}
          onClick={() => {
            copyToClipboard('info@lj-treckertreck.de');
            setShowCopy(true);
            setTimeout(() => {
              setShowCopy(false);
            }, 1500);
          }}
        >
          info@lj-treckertreck.de
        </a>
        . Wähle erst einen Teamnamen, bevor du anfängst. Bitte lese auch das Regelwerk.
      </p>
      <Overlay target={target.current} show={showCopy} placement="right">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Kopiert!
          </Tooltip>
        )}
      </Overlay>
      <p>
        Wie in jedem Jahr können Zuschauer- und Teamwagen am Freitag, den 13. September, ab 18:00
        Uhr (NICHT FRÜHER) angeliefert werden. Wir geben keine Stellplatzgarantie und nehmen auch
        keine Reservierungen vor - Wer zuerst kommt, mahlt zuerst. Alle Wagen, die vorher
        angeliefert oder auf die Fläche gestellt werden, können nicht berücksichtigt werden. Das
        Regelwerk findest Du
        <a
          target="_blank"
          href="http://www.grafschafter-trecker-treck.de/index.php/regeln"
          rel="noreferrer"
        >
          hier
        </a>
        .
        <a
          target="_blank"
          href="http://www.grafschafter-trecker-treck.de/index.php/faq"
          rel="noreferrer"
        >
          FAQ
        </a>
      </p>
      <div>
        <FloatingLabel controlId="eMail" label="eMail" className="mb-3">
          <Form.Control
            required
            type="mail"
            placeholder="eMail"
            value={team.email}
            onChange={(e) => handleTeamEntry('email', (e.target.value || '').replaceAll(' ', ''))}
          />
          <span>für die Anmeldebestätigung</span>
        </FloatingLabel>
        <h3>Team</h3>
        <div className="team">
          <FloatingLabel controlId="teamName" label="Name" className="mb-3">
            <Form.Control
              type="string"
              placeholder="Name"
              value={team.name}
              onChange={(e) => handleTeamEntry('name', e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="teamDescription" label="Beschreibung" className="mb-3">
            <Form.Control
              type="string"
              placeholder="Beschreibung"
              value={team.description}
              onChange={(e) => handleTeamEntry('description', e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="breakFirstPersonCount"
            label="Frühstück Anzahl"
            className="mb-3"
          >
            <Form.Control
              type="number"
              placeholder="Anzahl"
              value={team.breakFirstPersonCount}
              onChange={(e) => handleTeamEntry('breakFirstPersonCount', e.target.value)}
            />
          </FloatingLabel>
        </div>
      </div>
      <div className="registration-schema-wrapper">
        <h3>Personen</h3>
        <div>
          <Accordion>
            {persons.map((data, index) => (
              <Accordion.Item key={index} eventKey={index.toString()}>
                <Accordion.Header>{`${data.firstName} ${data.lastName}`}</Accordion.Header>
                <Accordion.Body>
                  <DataEdit
                    entry={data}
                    onSave={(data) => handleSavePerson(data, index)}
                    columns={personColumns}
                    onDelete={() => handleDeletePerson(index)}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ))}
            <Accordion.Item eventKey="-1">
              <Accordion.Header>Neue Person</Accordion.Header>
              <Accordion.Body>
                <DataEdit onSave={(data) => handleSavePerson(data)} columns={personColumns} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <div className="registration-schema-wrapper">
        <h3>Traktoren</h3>
        <div>
          <Accordion>
            {tractors.map((data, index) => (
              <Accordion.Item key={index} eventKey={index.toString()}>
                <Accordion.Header>{data.name}</Accordion.Header>
                <Accordion.Body>
                  <DataEdit
                    entry={data}
                    onSave={(data) => handleSaveTractors(data, index)}
                    columns={tractorColumns}
                    onDelete={() => handleDeleteTractors(index)}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ))}
            <Accordion.Item eventKey="-1">
              <Accordion.Header>Neuer Traktor</Accordion.Header>
              <Accordion.Body>
                <DataEdit onSave={(data) => handleSaveTractors(data)} columns={tractorColumns} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <div
        className="registration-schema-wrapper"
        style={!showDrives ? { opacity: '0.6' } : undefined}
      >
        <h3>Fahrten</h3>
        <div>
          {!showDrives && <div>Bitte lege erst Personen und Traktoren an.</div>}
          {showDrives && (
            <Accordion>
              {drives.map((data, index) => {
                const person = persons.find(
                  (x, index) => index === parseInt(data.personId, 10) || x._id === data.personId
                );
                const tractor = tractors.find(
                  (x, index) => index === parseInt(data.tractorId, 10) || x._id === data.tractorId
                );
                const driveClass = classes.find((x) => x._id === data.classId);
                const headerText = `${person ? `${person.firstName} ${person.lastName}` : ''} - ${
                  tractor ? tractor.name : ''
                } - ${driveClass ? driveClass.name : ''} - ${data ? data.startNumber : ''}`;
                return (
                  <Accordion.Item key={index} eventKey={index.toString()}>
                    <Accordion.Header>{headerText}</Accordion.Header>
                    <Accordion.Body>
                      <DataEdit
                        entry={data}
                        onSave={(data) => handleSaveDrive(data, index)}
                        columns={driveColumns}
                        onDelete={() => handleDeleteDrive(index)}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
              <Accordion.Item eventKey="-1">
                <Accordion.Header>Neue Fahrt</Accordion.Header>
                <Accordion.Body>
                  <DataEdit onSave={(data) => handleSaveDrive(data)} columns={driveColumns} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </div>
      </div>
      {showEmailUsed && (
        <div
          style={{
            display: 'block',
            position: 'fixed',
            backgroundColor: '#6363639c',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 99,
          }}
        >
          <Modal.Dialog>
            <Modal.Header closeButton>
              <Modal.Title>Email Adresse bereits verwendet</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>
                Es gibt schon ein Team, welches mit dieser eMail Adresse registriert wurde. Warst Du
                das? Prüfe deine Mails, wenns du Anpassungen machen möchtest oder wende dich an
                <a
                  ref={target}
                  onClick={() => {
                    copyToClipboard('info@lj-treckertreck.de');
                    setShowCopy(true);
                    setTimeout(() => {
                      setShowCopy(false);
                    }, 1500);
                  }}
                >
                  info@lj-treckertreck.de
                </a>
              </p>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowEmailUsed(false)}>
                Schließen
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      )}
    </div>
  );
};

export default Registration;
